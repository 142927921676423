<template>
    <header>
        <img alt="Vue logo" src="../assets/logo.svg" height="50" />
        <span style="color:#F6BF00">For</span>
        <span style="color:#E53A16">GAS</span>
        <nav>
            <ul>
                <li>
                    <router-link to="/home" class="routerlink">Home</router-link>
                </li>
                <li>
                    <router-link to="/about" class="routerlink"
                        >About</router-link
                    >
                </li>
                <li>
                  <v-btn
                    class="ma-2 white--text"
                    color="#004571"
                    @click="logout"
                  >Logout
                  </v-btn>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("signOut")
    }
  }
}
</script>

<style lang="less">
header {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 1rem;
    align-items: center;

    p {
        margin-left: 1rem;
    }
}

nav {
    margin-left: auto;

    ul {
        list-style: none;
    }

    ul li {
        display: inline-flex;
        margin-left: 1rem;
    }
}
</style>
