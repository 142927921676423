<template>
    <v-container fluid>
        <v-row align="center">
            <v-col cols="12">
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="receivedList"
                    :single-select="true"
                    item-key="index"
                    show-select
                    class="elevation-1"
                    @input="enterSelect()"
                    :loading="loadingData"
                    loading-text="Cargando datos... "
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                >
                    <v-progress-linear
                        v-show="loadingData"
                        slot="progress"
                        color="#004571"
                        indeterminate
                    ></v-progress-linear>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import url_utils from '../helpers/url_utils';

Vue.use(VueAxios, axios);

export default {
    name: 'DataList',
    props: ['value'],
    data() {
        return {
            loadingData: false,
            receivedList: undefined,
            selected: [],
            selecteddata: null,
            headers: [
                {
                    text: 'Label',
                    align: 'start',
                    sortable: false,
                    value: 'label'
                },
                {
                    text: 'Tipo Consumo',
                    align: 'start',
                    sortable: false,
                    value: 'consumptionType'
                },
                /*
                {
                    text: 'Estructura Entrenamiento',
                    align: 'start',
                    sortable: false,
                    value: 'dataStruct'
                },
                */
                {
                    text: 'Modelo',
                    align: 'start',
                    sortable: false,
                    value: 'model'
                },
                {
                    text: 'Fecha Creación',
                    align: 'start',
                    sortable: false,
                    value: 'creationdate'
                },
                {
                    text: 'Datos Predicción',
                    align: 'start',
                    sortable: false,
                    value: 'predsData'
                },
                {
                    text: 'Periodo Predicción',
                    align: 'start',
                    sortable: false,
                    value: 'prediction_period'
                },
                {
                    text: 'Lista Variables',
                    align: 'start',
                    sortable: false,
                    value: 'parametrical_features'
                },
                {
                    text: 'Automático',
                    align: 'start',
                    sortable: false,
                    value: 'auto'
                },
                {
                    text: 'Resultado',
                    align: 'start',
                    sortable: false,
                    value: 'resultado'
                }
            ],
            sortBy: 'label',
            sortDesc: true
        };
    },
    mounted() {
        setTimeout(this.executeRefresh.bind(this), 1000);
    },
    methods: {
        executeRefresh() {
            this.getPredictionList();
            setTimeout(this.executeRefresh.bind(this), 60000);
        },
        getPredictionList() {
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getReturnMetadataSummaryUrl(
                    'predictions',
                    'creationDate'
                ),
                headers: dict_auth
            };

            this.loadingData = true;
            Vue.axios(config).then(response => {
                function ModelItem(
                    index,
                    dirLabel,
                    consumptionType,
                    dataStruct,
                    model,
                    creationDate,
                    predsData,
                    prediction_period,
                    parametrical_features,
                    auto,
                    resultado
                ) {
                    this.index = index;
                    this.label = dirLabel;
                    this.consumptionType = consumptionType;
                    this.dataStruct = dataStruct;
                    this.model = model;
                    this.creationdate = creationDate;
                    this.predsData = predsData;
                    this.prediction_period = prediction_period;
                    this.parametrical_features = parametrical_features;
                    this.auto = auto;
                    this.resultado = resultado;
                }

                var itemList = [];
                for (var index in response.data.metadatas) {
                    var estadoProceso;
                    if (response.data.metadatas[index].inProcess == true)
                        estadoProceso = 'Procesando';
                    else estadoProceso = 'Ok';

                    var automatico;
                    if (response.data.metadatas[index].auto == true)
                        automatico = 'Sí';
                    else automatico = 'No';

                    var creationDate = '';
                    var predsData = '';
                    var prediction_period = '';
                    var parametrical_features = '';

                    if (response.data.metadatas[index].creationDate != null)
                        creationDate =
                            response.data.metadatas[index].creationDate;
                    if (response.data.metadatas[index].predsData != null)
                        predsData = response.data.metadatas[index].predsData;
                    if (
                        response.data.metadatas[index].prediction_period != null
                    )
                        prediction_period =
                            response.data.metadatas[index]
                                .prediction_period[0] +
                            ' - ' +
                            response.data.metadatas[index].prediction_period[1];
                    if (
                        response.data.metadatas[index].parametrical_features !=
                        null
                    )
                        parametrical_features =
                            response.data.metadatas[index]
                                .parametrical_features;

                    itemList.push(
                        new ModelItem(
                            index,
                            response.data.metadatas[index].dirLabel,
                            response.data.metadatas[index].consumptionType,
                            response.data.metadatas[index].dataStruct,
                            response.data.metadatas[index].model,
                            creationDate,
                            predsData,
                            prediction_period,
                            parametrical_features,
                            automatico,
                            estadoProceso
                        )
                    );
                }

                this.receivedList = itemList;
                this.loadingData = false;
            });
        },
        enterSelect() {
            if (this.selected[0] != undefined) {
                this.selecteddata = this.selected[0].label;
                this.$emit('input', this.selecteddata);
            }
            //console.log(this.selected.map(e => e.name)); // logs all the selected items.

            if (this.selected.length == this.itemsPerPage) {
                alert('selected all');
            }
        }
    }
};
</script>
