var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-data-table',{attrs:{"hide-default-footer":true,"loading":_vm.loadingData,"loading-text":"Cargando datos..."}},[_c('template',{slot:"no-data"},[_c('div')])],2)],1)],1),_c('v-row',{attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-list',{staticClass:"overflow-y-auto elevation-3 pa-3 ma-2",staticStyle:{"max-height":"50vh"}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItemIndustrial),callback:function ($$v) {_vm.selectedItemIndustrial=$$v},expression:"selectedItemIndustrial"}},[_c('v-subheader',[_c('b',[_vm._v("INDUSTRIAL")])]),_c('draggable',{staticStyle:{"min-height":"10px"},attrs:{"options":{
                                group: 'combine',
                                pull: 'clone',
                                put: false
                            }},model:{value:(_vm.industrial),callback:function ($$v) {_vm.industrial=$$v},expression:"industrial"}},[_vm._l((_vm.industrial),function(item){return [_c('v-list-item',{key:item.id},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)]})],2)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list',{staticClass:"overflow-y-auto elevation-3 pa-3 ma-2",staticStyle:{"max-height":"50vh"}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-subheader',[_c('b',[_vm._v("COMBINADO")])]),_c('draggable',{staticStyle:{"min-height":"10px"},attrs:{"group":"combine"},on:{"change":_vm.checkMixed},model:{value:(_vm.mixed),callback:function ($$v) {_vm.mixed=$$v},expression:"mixed"}},[_vm._l((_vm.mixed),function(item){return [_c('v-list-item',{key:item.id},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)]})],2)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-list',{staticClass:"overflow-y-auto elevation-3 pa-3 ma-2",staticStyle:{"max-height":"50vh"}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItemDomestico),callback:function ($$v) {_vm.selectedItemDomestico=$$v},expression:"selectedItemDomestico"}},[_c('v-subheader',[_c('b',[_vm._v("DOMESTICO")])]),_c('draggable',{staticStyle:{"min-height":"10px"},attrs:{"options":{
                                group: 'combine',
                                pull: 'clone',
                                put: false
                            }},model:{value:(_vm.domestico),callback:function ($$v) {_vm.domestico=$$v},expression:"domestico"}},[_vm._l((_vm.domestico),function(item){return [_c('v-list-item',{key:item.id},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)]})],2)],1)],1)],1)],1),_c('ConfirmDialog',{ref:"confirmCombination"}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"site-title",staticStyle:{"color":"black","padding-left":"10px"}},[_vm._v("Combinar Predicciones:")]),_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.calculating,"disabled":_vm.loadingData || _vm.mixed.length !== 2 || _vm.calculating,"color":"info"},on:{"click":_vm.getCombinedFile}},[_vm._v(" Lanzar "),(_vm.calculating)?_c('div'):_vm._e()])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }