/* eslint-disable */
import router from "../../plugins/router";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const replace_redirec_uri = {
  colon_double_slash: "://",
  slash: "/",
  replace_colon_double_slash : "%3A%2F%2F",
  replace_slash : "%2F"
}

const BASE_FORGOT_PASSWORD_URL = "$APP_DOMAIN$/forgotPassword?client_id=$CLIENT_ID$&response_type=code&scope=email+openid&redirect_uri=$REDIRECTURI$"

function getCognitoData() {
  const CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
  const APP_DOMAIN = process.env.VUE_APP_COGNITO_APP_DOMAIN;
  const REDIRECT_URI = process.env.VUE_APP_COGNITO_REDIRECT_URI;
  const USERPOOL_ID = process.env.VUE_APP_COGNITO_USERPOOL_ID;
  const REDIRECT_URI_SIGNOUT = process.env.VUE_APP_COGNITO_REDIRECT_URI_SIGNOUT;
  const APP_URL = process.env.VUE_APP_APP_URL;

  let cognitoData = {
    ClientId : CLIENT_ID, // Your client id here
    AppWebDomain : APP_DOMAIN,
    TokenScopesArray : ['openid', 'email', 'profile'],
    RedirectUriSignIn : REDIRECT_URI,
    RedirectUriSignOut : REDIRECT_URI_SIGNOUT,
    UserPoolId : USERPOOL_ID,
  }

  return cognitoData
}

let poolData = {
  ClientId: getCognitoData().ClientId,
  UserPoolId: getCognitoData().UserPoolId
}

const state = {
  userPool: [],
  authDetails: '',
  userData: '',
  cognitoUser: null,
  tokens: {
    accessToken: '',
    idToken: '',
    refreshToken: ''
  },
  username: '',
  user_type: -1,
  errcode: '',
  attributes: [],
  authenticated: false,
  neededChangePassword: false,
}

const getters = {
  getStateAttributes (state) {
    return state.attributes
  },
  getTokenHeader (state) {
    return state.tokens.idToken
  },
  getNeededChangePassword (state) {
    return state.neededChangePassword
  },
  isUserAuthenticated(state) {
    return state.authenticated === true
  },
  isUserFull(state) {
    return state.user_type === "0"
  },
  getErrMessage(state) {
    let msg = ''
    if (state.errcode !== '') {
      if (state.errcode === '"NotAuthorizedException"') {
        msg = 'Usuario o password incorrectos'
      } else if (state.errcode === '"UserNotFoundException"') {
        msg = 'Usuario no encontrado'
      } else if (state.errcode === '"LimitExceededException"') {
        msg = 'Limite de intentos excedido, intentalo despues de un tiempo'
	    } else if (state.errcode === '"TemporaryPasswordExpired"') {
        msg = 'Su password temporal ha expirado'
      } else {
        msg = 'Ha ocurrido un error!'
      }
    }
    else
      msg = ''
    return msg
  }
}

const mutations = {
  signOut (state) {
    try {
      if (state.cognitoUser === null || state.cognitoUser === undefined) {
        state.cognitoUser = null;
      }
      else {
        state.cognitoUser.signOut();
      }
    } catch (error) {
      console.log(JSON.stringify(error.code))
    }
    state.sessionToken = null
    state.authenticated = false
    state.username = ''
    state.userPool = []
  },
  setNeededChangePassword(state, value) {
    state.neededChangePassword = value
  },
  setAttributes (state, attributes) {
    state.attributes = attributes
    state.username = state.attributes.filter(function (Obj) {
      return Obj.Name === 'email'
    })[0].Value
    state.user_type = state.attributes.filter(function(Obj) {
      return Obj.Name === 'custom:user_type'
    })[0].Value
  },
  setUsername (state, payload) {
    state.username = payload
  },
  signIn (state) {
    state.authenticated = true
  },
  setUserPool (state) {
    state.userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
  },
  setTokens (state, payload) {
    state.tokens.accessToken = payload.getAccessToken().getJwtToken()
    state.tokens.idToken = payload.getIdToken().getJwtToken()
    state.tokens.refreshToken = payload.getRefreshToken().getToken()
  },
  setCognitoUser (state, payload) {
    state.cognitoUser = payload
  },
  setCognitoDetails (state, authData) {
    state.authDetails = new AmazonCognitoIdentity.AuthenticationDetails(authData)
    state.userData = { Username: authData.Username, Pool: state.userPool }
    state.cognitoUser = new AmazonCognitoIdentity.CognitoUser(state.userData)
  },
  setError (state, payload) {
    state.errcode = payload
  },
  clearError (state) {
    state.errcode = ''
  }
}

const actions = {
  signIn ({ state, commit, dispatch }, authData) {
    commit('clearError')
    commit('setUserPool')
    commit('setCognitoDetails', authData)
    state.cognitoUser.authenticateUser(state.authDetails, {
      onSuccess: (result) => {
        commit('setTokens', result)
        commit('signIn')
        dispatch('getUserAttributes')
        dispatch('setLogoutTimer', 3600)
        router.push('/home')
      },
      onFailure: (err) => {
		    if (err.message.toUpperCase().includes("Temporary password has expired".toUpperCase())){
          commit('setError', '"TemporaryPasswordExpired"')
        }
        else {
          commit('setError', JSON.stringify(err.code))
        }
      },
      newPasswordRequired: function(userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.
        // the api doesn't accept this field back
        commit('setNeededChangePassword', true)
        router.push('/confirm')
      }
    })
  },
  newPassword({ state, commit, dispatch }, data) {
    const authData = {
      Username: data.Username,
      Password: data.Password
    }
    commit('clearError')
    commit('setUserPool')
    commit('setCognitoDetails', authData)
    const newPassword = data.NewPassword
    state.cognitoUser.authenticateUser(state.authDetails, {
      onSuccess: (result) => {
        commit('setTokens', result)
        commit('signIn')
        router.push('/home')
        dispatch('getUserAttributes')
        dispatch('setLogoutTimer', 3600)
      },
      onFailure: (err) => {
        commit('setError', JSON.stringify(err.code))
        console.log(state.errcode)
      },
      newPasswordRequired: function(userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.
        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        delete userAttributes.email;

        state.cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
          onSuccess: (result) => {
            commit('setNeededChangePassword', false)
            commit('signOut')
            router.push('/login')
          },
          onFailure: (err) => {
            alert(err)
            console.log('sign in failure')
            commit('setError', JSON.stringify(err.code))
          },
        });
      }
    })
  },
  tryAutoSignIn ({ state, commit, dispatch }) {
    commit('setUserPool')
    let cognitoUser = state.userPool.getCurrentUser()
    if (cognitoUser != null) {
      commit('setCognitoUser', cognitoUser)
      state.cognitoUser.getSession(function (err, session) {
        if (err) {
          console.error(JSON.stringify(err))
        } else {
          commit('setTokens', session)
          commit('signIn')
          dispatch('getUserAttributes')
          dispatch('setLogoutTimer', 3600)
          router.push('/home')
        }
      })
    }
  },
  getUserAttributes ({ commit, dispatch }) {
    state.cognitoUser.getUserAttributes(function (err, attributes) {
      if (err) {
        console.error(JSON.stringify(err))
      } else {
        commit('setAttributes', attributes)
      }
    })
  },
  setLogoutTimer ({ state, commit, dispatch }, expirationTime) {
    setTimeout(() => {
      dispatch('signOut')
    }, expirationTime * 1000)
  },
  signOut ({ commit }) {
    commit('signOut')
    router.push('/login')
  }
}

export const auth = {
  state,
  getters,
  mutations,
  actions
}
