<template>
    <div>
        <v-container fluid fill-height>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="left">
                    <label
                        style="color: black; padding-left: 10px"
                        class="site-title"
                        ><b>Crear Predicción</b></label
                    >
                    <v-dialog v-model="infoCrearPrediccionVisible" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                                >mdi-information</v-icon
                            >
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                Crear Predicción
                            </v-card-title>
                            <v-card-text>
                                En la tabla aparecen los modelos entrenados
                                disponibles y sus características principales,
                                así como las variables externas con las que fue
                                entrenado y el horizonte temporal para el que
                                puede realizar predicciones. 1. Seleccione el
                                modelo deseado. 2. Seleccione cada una de las
                                variables disponibles y un set de valores
                                predefinidos en los menús desplegables. En la
                                tabla inferior puede modificar los valores
                                predefinidos si lo desea. 3. Repita el paso 2.
                                Para cada una de las variables 4. Realice la
                                predicción
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="#004571"
                                    text
                                    @click="infoCrearPrediccionVisible = false"
                                >
                                    Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
                <v-col md="12">
                    <ModelList
                        v-model="model"
                        :enabledDialog="predictionDataModified"
                    ></ModelList>
                </v-col>
            </v-row>
            <ConfirmDialog ref="confirmPrediction" />
            <v-row no-gutters align="center" justify="center">
                <v-col md="10" v-if="model.selected !== null">
                    <PredictionInput
                        :modelMetadata="model.metadata"
                        :calculating="calculating"
                        @launchPrediction="launchPrediction"
                        @elementChanged="setPredictionDataModified"
                    ></PredictionInput>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);
import ModelList from '@/components/ModelList.vue';
import PredictionInput from '@/components/PredictionInput';
import url_utils from '../helpers/url_utils';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';

export default {
    name: 'Tab3',
    data() {
        return {
            model: {
                selected: null,
                metadata: null
            },
            prediccion: {
                selected: null
            },
            predictionDataModified: false,
            infoCrearPrediccionVisible: false,
            calculating: {
                ind: false,
                dom: false
            }
        };
    },
    computed: {
        selectedmodelcomputed() {
            if (this.model.selected === null) return null;
            else {
                this.setPredictionDataModified(false);
                return this.model.selected.label;
            }
        },
        selectedpredictioncomputed() {
            if (this.prediccion.selected === null) return null;
            else return this.prediccion.selected.label;
        },
        sourceInformePrevision() {
            if (this.selectedpredictioncomputed) {
                return url_utils.getHtmUrl(
                    'predictions',
                    this.selectedpredictioncomputed,
                    'ReportPrediccion.html'
                );
            } else {
                return '';
            }
        }
    },
    components: {
        ModelList,
        PredictionInput,
        ConfirmDialog
    },
    mounted() {},
    watch: {},
    methods: {
        setPredictionDataModified(value) {
            this.predictionDataModified = value;
        },
        async launchPrediction(parametros, consumptionType) {
            const isIndustrial = consumptionType === 'IND';
            const isDomestic = consumptionType === 'DOM';
            var tipoConsumo;
            var textDialog;
            var tiempoDialogo;
            if (isIndustrial) {
                tipoConsumo = 'industrial';
                textDialog =
                    '¿Iniciar predicción Industrial? (seguimiento del cálculo en pestaña de Estado Cálculos)';
                tiempoDialogo = 'Confirma la Operación (duración mínima 40 minutos)';
            } else if (isDomestic) {
                tipoConsumo = 'domestico';
                textDialog =
                    '¿Iniciar predicción Modelo Doméstico? (seguimiento del cálculo en pestaña de Estado Cálculos)';
                tiempoDialogo = 'Confirma la Operación (duración mínima 12 minutos)';
            }

            if (
                await this.$refs.confirmPrediction.open(
                    tiempoDialogo,
                    textDialog
                )
            ) {
                if (isIndustrial) this.calculating.ind = true;
                else if (isDomestic) this.calculating.dom = true;

                let params = parametros;

                //console.log(params);

                let token = this.$store.getters['getTokenHeader']
                let dict_auth = {
                  'Authorization': token,
                  'Content-Type': "application/json"
                };
                dict_auth['Content-Type'] = 'application/json';
                var config = {
                    method: 'post',
                    url: url_utils.getPredictionUrl(
                        this.selectedmodelcomputed,
                        tipoConsumo
                    ),
                    headers: dict_auth,
                    params: { params }
                };
                Vue.axios(config).then(response => {
                    if (response.status == 200) {
                        if (isIndustrial) {
                            setTimeout(
                                () => (this.calculating.ind = false),
                                90000
                            );
                        } else if (isDomestic) {
                            setTimeout(
                                () => (this.calculating.dom = false),
                                90000
                            );
                        }
                    } else {
                        if (isIndustrial) this.calculating.ind = false;
                        else if (isDomestic) this.calculating.dom = false;
                    }
                });
            }
        }
    }
};
</script>

<style lang="less">
.header-row {
    align-items: center;
}

.hero-image {
    background-position-y: -35px;
}

#app,
#app iframe {
    height: 100vh;
    width: 100vw;
    border: none;
    box-sizing: border-box;
}

body {
    margin: 0;
}
</style>
