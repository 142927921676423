<template>
    <div>
        <v-container fluid fill-height>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="left">
                    <label
                        style="color: black; padding-left: 10px"
                        class="site-title"
                        ><b>Consultar Predicción</b></label
                    >
                    <v-dialog
                        v-model="infoConsultarPrediccionVisible"
                        width="500"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                                >mdi-information</v-icon
                            >
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                Consultar Predicción
                            </v-card-title>
                            <v-card-text>
                                En la tabla se encuentran las predicciones
                                disponibles, la información sobre el modelo
                                utilizado, el horizonte temporal y las variables
                                utilizadas. Al seleccionar un set podrá
                                visualizar los siguientes informes: A) Informe
                                de predicción: comparación de las predicciones
                                con el consumo del año anterior. B) Log
                                predicción: informe técnico de las predicciones
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="#004571"
                                    text
                                    @click="
                                        infoConsultarPrediccionVisible = false
                                    "
                                >
                                    Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
                <v-col md="12">
                    <PredictionsList
                        v-model="selectedpredictionname"
                    ></PredictionsList>
                </v-col>
                <v-col cols="12">
                    <v-tabs
                        align-with-title
                        v-if="selectedpredictionname !== null"
                    >
                        <v-tab href="#one" @click="tabclick"
                            >Informe Predicción</v-tab
                        >
                        <v-tab href="#two">Descargar Datos</v-tab>
                        <v-tab href="#three" @click="tabclick"
                            >Log Predicción</v-tab
                        >
                        <v-tab-item value="one">
                            <br />
                            <v-data-table
                                :hide-default-footer="true"
                                :loading="loadingData"
                                loading-text="Cargando datos..."
                            >
                                <template slot="no-data">
                                    <div></div>
                                </template>
                            </v-data-table>
                            <iframe
                                :src="sourceInformePrevision"
                                style="border: 0; width: 100%; overflow: auto"
                                allowfullscreen="true"
                                id="iframeResizer1"
                                scrolling="yes"
                                @load="onsourceLoad"
                            ></iframe>
                        </v-tab-item>
                        <v-tab-item value="two">
                            <v-row align="center" justify="center">
                                <br />
                                <v-col>
                                    <v-btn
                                        color="#004571"
                                        class="ma-2 white--text"
                                        @click="downloadCSV('predictions.csv')"
                                    >
                                        Descargar Prediccion (.csv)
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item value="three">
                            <br />
                            <v-data-table
                                :hide-default-footer="true"
                                :loading="loadingData"
                                loading-text="Cargando datos..."
                            >
                                <template slot="no-data">
                                    <div></div>
                                </template>
                            </v-data-table>
                            <iframe
                                :src="sourceLogPrevision"
                                style="border: 0; width: 100%; overflow: auto"
                                allowfullscreen="true"
                                id="iframeResizer2"
                                scrolling="yes"
                                @load="onsourceLoad"
                            ></iframe>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import utils from '@/helpers/utils';
import PredictionsList from '@/components/PredictionsList.vue';
import url_utils from '../helpers/url_utils';

Vue.use(VueAxios, axios);

export default {
    name: 'Tab2',
    data() {
        return {
            selectedpredictionname: null,
            loadingData: false,
            infoConsultarPrediccionVisible: false
        };
    },
    computed: {
        sourceInformePrevision() {
            if (
                this.selectedpredictioname !== null ||
                this.selectedpredictioname !== undefined
            ) {
                return url_utils.getHtmUrl(
                    'predictions',
                    this.selectedpredictionname,
                    'report_prediction.html'
                );
            } else {
                return '';
            }
        },
        sourceLogPrevision() {
            if (
                this.selectedpredictioname !== null ||
                this.selectedpredictioname !== undefined
            ) {
                return url_utils.getHtmUrl(
                    'predictions',
                    this.selectedpredictionname,
                    'ReportPrediccion.html'
                );
            } else {
                return '';
            }
        }
    },
    components: {
        PredictionsList
    },
    mounted() {},
    watch: {
        selectedpredictionname: function() {
            this.tabclick();
        }
    },
    methods: {
        setPredictionDataModified(value) {
            this.predictionDataModified = value;
        },
        tabclick() {
            this.loadingData = true;
            setTimeout(() => (this.loadingData = false), 3000);
        },
        onsourceLoad() {
            this.loadingData = false;
        },
        downloadCSV(name) {
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getDownloadFileUrl(
                    'predictions',
                    this.selectedpredictionname,
                    name
                ),
                headers: dict_auth
            };
            Vue.axios(config)
                .then(response => {
                    const url = response.data;
                    utils.downloadFromUrl(url);
                })
                .catch(error => {
                    console.log('Error');
                    console.log(error.message);
                });
        }
    }
};
</script>

<style lang="less">
.header-row {
    align-items: center;
}

.hero-image {
    background-position-y: -35px;
}

#app,
#app iframe {
    height: 100vh;
    width: 100vw;
    border: none;
    box-sizing: border-box;
}

body {
    margin: 0;
}
</style>
