<template>
    <v-container fluid fill-height>
        <v-row class="list px-3 mx-auto" align="center" justify="center">
            <v-col class="d-flex" md="4">
                <v-select
                    v-model="selectedvariable"
                    :items="options"
                    :allow-empty="false"
                    @change="setDefaultRange"
                    label="Parámetro"
                    dense
                    outlined
                >
                </v-select>
            </v-col>
            <v-col class="d-flex" md="4">
                <v-select
                    v-model="selectedrange"
                    :items="rangeOptions"
                    :disabled="selectedvariable === null"
                    label="Valor de Referencia"
                    dense
                    outlined
                >
                </v-select>
            </v-col>
            <v-col md="4">
                <v-btn
                    class="ma-2"
                    :disabled="
                        selectedvariable === null || selectedrange === null
                    "
                    color="info"
                    @click="setPredictionParameters()"
                >
                    Seleccionar Referencia
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="list px-3 mx-auto" align="center" justify="center">
            <v-col md="12">
                <v-card class="mx-auto" tile>
                    <v-card-title
                        >Parámetro
                        {{
                            selectedvariable === null ? '' : selectedvariable
                        }}</v-card-title
                    >
                    <v-data-table
                        :headers="headers"
                        :items="getParametros"
                        disable-pagination
                        :hide-default-footer="true"
                    >
                        <template v-slot:body="{ items, headers }">
                            <tbody>
                                <tr v-for="(item, idx) in items" :key="idx">
                                    <td
                                        v-for="(header, key) in headers"
                                        :key="key"
                                    >
                                        <v-edit-dialog
                                            :return-value.sync="
                                                item[header.value]
                                            "
                                            @save="save"
                                            @cancel="cancel"
                                            large
                                        >
                                            {{ item[header.value].toFixed(3) }}
                                            <template v-slot:input>
                                                <div class="mt-4 text-h6">
                                                    Update value
                                                </div>
                                                <v-text-field
                                                    @input="
                                                        setPredictionParameter(
                                                            $event,
                                                            header.value
                                                        )
                                                    "
                                                    :value="item[header.value]"
                                                    label="Edit"
                                                    type="number"
                                                    single-line
                                                ></v-text-field>
                                            </template>
                                        </v-edit-dialog>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="list px-3 mx-auto" align="center" justify="center">
            <v-col md="12">
                <v-card class="mx-auto" tile>
                    <v-card-title>Parámetros seleccionados</v-card-title>
                    <v-data-table
                        :headers="selectedPredictionHeaders"
                        :items="getPredictionParameters"
                        disable-pagination
                        :hide-default-footer="true"
                        class="elevation-1"
                    >
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="(item, index) in items" :key="index">
                                    <td>{{ item.key }}</td>
                                    <td class="text-center">
                                        {{ item.range }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-snackbar
                        v-model="snack"
                        :timeout="3000"
                        :color="snackColor"
                    >
                        {{ snackText }}

                        <template v-slot:action="{ attrs }">
                            <v-btn v-bind="attrs" text @click="snack = false">
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-card>
            </v-col>
        </v-row>
        <v-row align="center" justify="center" v-if="selectedIsIndustrial">
            <v-col md="4">
                <label
                    style="color: black; padding-left: 10px"
                    class="site-title"
                    >Predicción Industrial:</label
                >
                <v-btn
                    class="ma-2"
                    :loading="calculating.ind"
                    :disabled="calculating.ind || selectedrange === null"
                    color="info"
                    @click="launchEventPrediction('IND')"
                >
                    Lanzar
                    <div v-if="calculating.ind"></div>
                </v-btn>
            </v-col>
        </v-row>
        <v-row align="center" justify="center" v-if="selectedIsDomestico">
            <v-col md="4">
                <label
                    style="color: black; padding-left: 10px"
                    class="site-title"
                    >Predicción Domestico:</label
                >
                <v-btn
                    class="ma-2"
                    :loading="calculating.dom"
                    :disabled="calculating.dom || selectedrange === null"
                    color="info"
                    @click="launchEventPrediction('DOM')"
                >
                    Lanzar
                    <div v-if="calculating.dom"></div>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

export default {
    props: ['calculating', 'modelMetadata'],
    name: 'PredictionInput',
    data() {
        return {
            title: 'Parameters:',
            headers: [
                { text: 'Dic', value: '0', sortable: false },
                { text: 'Ene', value: '1', sortable: false },
                { text: 'Feb', value: '2', sortable: false },
                { text: 'Mar', value: '3', sortable: false },
                { text: 'Abr', value: '4', sortable: false },
                { text: 'May', value: '5', sortable: false },
                { text: 'Jun', value: '6', sortable: false },
                { text: 'Jul', value: '7', sortable: false },
                { text: 'Ago', value: '8', sortable: false },
                { text: 'Sep', value: '9', sortable: false },
                { text: 'Oct', value: '10', sortable: false },
                { text: 'Nov', value: '11', sortable: false },
                { text: 'Dic', value: '12', sortable: false },
                { text: 'Ene', value: '13', sortable: false },
                { text: 'Feb', value: '14', sortable: false },
                { text: 'Mar', value: '15', sortable: false },
                { text: 'Abr', value: '16', sortable: false },
                { text: 'May', value: '17', sortable: false },
                { text: 'Jun', value: '18', sortable: false },
                { text: 'Jul', value: '19', sortable: false },
                { text: 'Ago', value: '20', sortable: false },
                { text: 'Sep', value: '21', sortable: false },
                { text: 'Oct', value: '22', sortable: false },
                { text: 'Nov', value: '23', sortable: false },
                { text: 'Dic', value: '24', sortable: false },
                { text: 'Ene', value: '25', sortable: false },
                { text: 'Feb', value: '26', sortable: false }
            ],
            selectedPredictionHeaders: [
                {
                    text: 'Paràmetro',
                    value: 'key',
                    align: 'center',
                    sortable: false
                },
                {
                    text: 'Valor de Referencia',
                    value: 'range',
                    align: 'center',
                    sortable: false
                }
            ],
            parametros: null,
            selectedvariable: null,
            selectedrange: null,
            selectedPredictionParameters: [],
            snack: false,
            snackColor: '',
            snackText: ''
        };
    },
    computed: {
        options: function() {
            return this.getParametricFeatures();
        },
        rangeOptions: function() {
            return this.getDefaultValuesPredictRange();
        },
        getParametros() {
            if (this.selectedvariable === null || this.selectedrange === null)
                return [];
            else if (this.parametros === null) return [];
            return [
                this.parseNumbersToFloat(
                    this.parametros[this.selectedvariable][this.selectedrange]
                )
            ];
        },
        getPredictionParameters() {
            return this.selectedPredictionParameters;
        },
        getModelMetadata() {
            return this.modelMetadata;
        },
        selectedIsIndustrial() {
            if (this.modelMetadata === null) return false;
            return (
                this.modelMetadata.consumptionType.toLowerCase() ===
                'industrial'
            );
        },
        selectedIsDomestico() {
            if (this.modelMetadata === null) return false;
            return (
                this.modelMetadata.consumptionType.toLowerCase() === 'domestico'
            );
        }
    },
    methods: {
        launchEventPrediction(consumptionType) {
            let params = this.getFilteredParameters();
            this.$emit('launchPrediction', params, consumptionType);
        },
        parseNumbersToFloat(values) {
            let params = {};
            for (const [key, value] of Object.entries(values)) {
                let val = value;
                if (typeof val !== 'number') {
                    if (val === null) val = 0;
                    else val = parseFloat(val);
                }
                params[key] = val;
            }
            return params;
        },
        getFilteredParameters() {
            let final_structure = {};
            final_structure['YEAR'] = this.parametros['YEAR'];
            final_structure['MONTH'] = this.parametros['MONTH'];
            for (let i in this.selectedPredictionParameters) {
                let item = this.selectedPredictionParameters[i];
                final_structure[item.key] = {};
                final_structure[item.key][
                    item.range
                ] = this.parseNumbersToFloat(
                    this.parametros[item.key][item.range]
                );
            }

            return final_structure;
        },
        getValuesToPredict() {
            if (
                this.getModelMetadata === undefined ||
                this.getModelMetadata === null
            )
                return null;
            return this.getModelMetadata.default_values_to_predict;
        },
        getTextParameter(index) {
            return {
                text:
                    this.getValuesToPredict()['MONTH'][index] +
                    '/' +
                    this.getValuesToPredict()['YEAR'][index],
                value: index.toString(),
                sortable: false
            };
        },
        generateHeaders() {
            this.headers = [
                this.getTextParameter(0),
                this.getTextParameter(1),
                this.getTextParameter(2),
                this.getTextParameter(3),
                this.getTextParameter(4),
                this.getTextParameter(5),
                this.getTextParameter(6),
                this.getTextParameter(7),
                this.getTextParameter(8),
                this.getTextParameter(9),
                this.getTextParameter(10),
                this.getTextParameter(11),
                this.getTextParameter(12),
                this.getTextParameter(13),
                this.getTextParameter(14),
                this.getTextParameter(15),
                this.getTextParameter(16),
                this.getTextParameter(17),
                this.getTextParameter(18),
                this.getTextParameter(19),
                this.getTextParameter(20),
                this.getTextParameter(21),
                this.getTextParameter(22),
                this.getTextParameter(23),
                this.getTextParameter(24),
                this.getTextParameter(25),
                this.getTextParameter(26)
            ];
        },
        getParametricFeatures() {
            if (
                this.getModelMetadata === undefined ||
                this.getModelMetadata === null
            ) {
                return ['Pending Model Selection'];
            } else {
                return this.getModelMetadata.parametrical_features;
            }
        },
        getDefaultValuesPredictRange() {
            if (
                this.getModelMetadata === undefined ||
                this.getModelMetadata == null
            ) {
                return ['Pending Feature Selection'];
            }
            if (
                this.selectedvariable === '' ||
                this.selectedvariable === 'Pending Model Selection' ||
                this.selectedvariable === null
            ) {
                return ['Pending Feature Selection'];
            }
            return Object.keys(
                this.getValuesToPredict()[this.selectedvariable]
            );
        },
        setDefaultRange() {
            this.selectedrange = null;
            this.setDefaultValues();
        },
        setDefaultValues() {
            if (this.selectedvariable === null) {
                this.selectedvariable = this.getParametricFeatures()[0];
            }
            if (this.selectedrange === null) {
                this.selectedrange = Object.keys(
                    this.getValuesToPredict()[this.selectedvariable]
                )[0];
            }
        },
        setDefaultPredictionParameters() {
            for (const [key, value] of Object.entries(
                this.getValuesToPredict()
            )) {
                if (key === 'MONTH' || key === 'YEAR') continue;
                this.setPredictionParameters(key, Object.keys(value)[0]);
            }
        },
        setPredictionParameters(key = null, range = null) {
            let index = -1;
            for (let i in this.selectedPredictionParameters) {
                if (
                    this.selectedPredictionParameters[i].key ===
                    this.selectedvariable
                ) {
                    index = i;
                    break; // If you want to break out of the loop once you"ve found a match
                }
            }
            if (index !== -1)
                this.selectedPredictionParameters.splice(index, 1);

            this.selectedPredictionParameters.push({
                key: key === null ? this.selectedvariable : key,
                range: range === null ? this.selectedrange : range
            });
        },
        setPredictionParameter(newValue, index) {
            if (
                newValue === undefined ||
                newValue === null ||
                newValue === ''
            ) {
                newValue = '0';
            }
            this.parametros[this.selectedvariable][this.selectedrange][
                index
            ] = parseFloat(newValue);
        },
        save() {
            this.$emit('elementChanged', true);
            this.snack = true;
            this.snackColor = 'success';
            this.snackText = 'Data saved';
        },
        cancel() {
            this.snack = true;
            this.snackColor = 'error';
            this.snackText = 'Canceled';
        },
        createParametersTree() {
            let parametrosTree = {};
            for (const [key, value] of Object.entries(
                this.getValuesToPredict()
            )) {
                parametrosTree[key] = value;
            }
            this.parametros = null;
            this.parametros = parametrosTree;
        },
        initData() {
            this.parametros = null;
            this.selectedPredictionParameters = [];
            this.selectedvariable = null;
            this.selectedrange = null;
        }
    },
    mounted() {},
    components: {},
    watch: {
        getModelMetadata: function() {
            this.initData();
            this.generateHeaders();
            this.createParametersTree();
            this.setDefaultPredictionParameters();
            this.setDefaultValues();
        }
    }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
