<template >
  <v-container fluid fill-height v-if="!isUsserLogged">
    <v-row class="fill-height" align="center" justify="center">
      <v-col cols="12" xs="12" sm="10" md="8" lg="4" xl="4">
        <v-card class="elevation-12">
          <v-card-title primary-title class="justify-center">
            <v-toolbar dark color="#004571">
              <v-row align="center" justify="space-between">
                <v-col cols="12">
                  <img alt="Vue logo" src="../../assets/logo.svg" height="50">
                </v-col>
              </v-row>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-if="errmsg !== ''"
              color="red"
              dense
              prominent
              dismissible
              type="error"
            >
              {{ errmsg }}
            </v-alert>
            <form ref="form">
              <div class="form-group">
                <v-text-field
                  v-model="user.username"
                  name="username"
                  label="Username"
                  type="text"
                  placeholder="username"
                  :error-messages="checkUsername"
                ></v-text-field>
              </div>
              <div class="form-group">
                <v-text-field
                  v-model="user.password"
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="password"
                  :error-messages="checkPassword"
                ></v-text-field>
              </div>
              <v-btn class="ma-2 white--text"
                     color="#004571"
                     :disabled="loading"
                     @click="handleSubmit()">
                Login
              </v-btn>
            </form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--          </v-flex>-->
    <!--        </v-layout>-->
  </v-container>
</template>

<script>
// import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
// import jwt_decode from 'jwt-decode'
// import auth from "@/app/auth";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      user: {
        username: "",
        password: ""
      },
      submitted: false,
      loading: false
    };
  },
  validations: {
    user: {
      username: { required },
      password: { required, minLength: minLength(8) }
    }
  },
  computed: {
    isUsserLogged() {
      return this.$store.getters['isUserAuthenticated'];
    },
    errmsg: function() {
      return this.$store.getters["getErrMessage"];
    },
    checkUsername: function() {
      const errors = [];
      if (!this.$v.user.username.$dirty) return null;
      if (!this.$v.user.username.required) errors.push("Usuario es obligatorio");
      return errors;
    },
    checkPassword: function() {
      const errors = [];
      if (!this.$v.user.password.$dirty) return null;
      if (!this.$v.user.password.required) errors.push("Password es obligatorio");
      if (!this.$v.user.password.minLength) errors.push("La longitud mínima es 8");
      return errors;
    }
  },
  methods: {
    handleSubmit() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      let authData = {
        Username: this.user.username,
        Password: this.user.password
      };
      this.$store.dispatch("signIn", authData);
      this.loading = false;
      this.submitted = false;
    }
  }
};
</script>

<style scoped>

</style>
