<template>
    <div>
        <v-container fluid>
            <v-row align="center" justify="start">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="left">
                    <label
                        style="color: black; padding-left: 10px"
                        class="site-title"
                        ><b>Crear Modelo Entrenado</b></label
                    >
                    <v-dialog
                        v-model="infoCrearModeloEntrenadoVisible"
                        width="500"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                                >mdi-information</v-icon
                            >
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                Crear Modelo Entrenado
                            </v-card-title>
                            <v-card-text>
                                1. Seleccione en la tabla un tablón de datos con
                                las variables necesarias para el entrenamiento
                                del modelo. Salvo excepciones, se recomienda
                                usar el más reciente. 2. Seleccione las
                                variables externas con las que se entrenará el
                                modelo. 3. Escriba un identificador (texto) para
                                el modelo que se entrenará (opcional)
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="#004571"
                                    text
                                    @click="
                                        infoCrearModeloEntrenadoVisible = false
                                    "
                                >
                                    Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    align="center"
                    justify="start"
                    cols="10"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                >
                    <TrainDataList
                        v-model="trainDataListSelected"
                    ></TrainDataList>
                </v-col>
            </v-row>
            <v-row
                align="center"
                justify="space-around"
                v-if="trainDataListSelected.length > 0"
            >
                <DateRangePicker v-model="dates"></DateRangePicker>
            </v-row>
            <v-row
                align="center"
                justify="center"
                v-if="trainDataListSelected.length > 0"
            >
                <v-col cols="4">
                    <v-select
                        v-model="variableExternaSeleccionada"
                        :items="variablesExternas"
                        item-text="label"
                        item-value="key"
                        :disabled="trainDataListSelected.length === 0"
                        multiple
                        label="Variables Externas"
                        clearable
                        v-if="selectedIsIndustrial"
                    >
                    </v-select>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        v-model="sufijoModeloEntrenado"
                        :disabled="trainDataListSelected.length === 0"
                        color="info"
                        label="Sufijo Modelo Entrenado: (Opcional)"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <ConfirmDialog ref="confirmTraining" />
            <v-row
                align="center"
                justify="center"
                v-if="trainDataListSelected.length > 0"
            >
                <v-col cols="4">
                    <label
                        style="color: black; padding-left: 10px"
                        class="site-title"
                        v-if="selectedIsIndustrial"
                        >Entrenamiento Industrial:</label
                    >
                    <v-btn
                        class="ma-2 white--text"
                        :loading="calculating.ind"
                        :disabled="calculating.ind"
                        color="#004571"
                        @click="launchtraining('IND')"
                        v-if="selectedIsIndustrial"
                    >
                        Lanzar
                        <div v-if="calculating.ind"></div>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                align="center"
                justify="center"
                v-if="trainDataListSelected.length > 0"
            >
                <v-col cols="4">
                    <label
                        style="color: black; padding-left: 10px"
                        class="site-title"
                        v-if="selectedIsDomestic"
                        >Entrenamiento Doméstico:</label
                    >
                    <v-btn
                        class="ma-2 white--text"
                        :loading="calculating.dom"
                        :disabled="calculating.dom"
                        color="#004571"
                        @click="launchtraining('DOM')"
                        v-if="selectedIsDomestic"
                    >
                        Lanzar
                        <div v-if="calculating"></div>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import TrainDataList from '@/components/TrainDataList.vue';
import { mapGetters } from 'vuex';
import url_utils from '../helpers/url_utils';
import DateRangePicker from './DateRangePicker';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';

Vue.use(VueAxios, axios);

export default {
    name: 'Tab5',
    data() {
        return {
            variableExternaSeleccionada: [],
            sufijoModeloEntrenado: '',
            variablesExternas: [],
            loadingData: false,
            infoCrearModeloEntrenadoVisible: false,
            trainDataListSelected: [],
            calculating: {
                ind: false,
                dom: false
            },
            dates: []
        };
    },
    computed: {
        ...mapGetters(['getTrainMetaData']),
        trainData() {
            return this.getTrainMetaData;
        },
        selectedIsIndustrial() {
            if (this.trainDataListSelected.length === 0) {
                return false;
            }
            return (
                this.trainDataListSelected[0].consumptionType.toLowerCase() ===
                'industrial'
            );
        },
        selectedIsDomestic() {
            if (this.trainDataListSelected.length === 0) {
                return false;
            }
            return (
                this.trainDataListSelected[0].consumptionType.toLowerCase() ===
                'domestico'
            );
        }
    },
    components: {
        DateRangePicker,
        TrainDataList,
        ConfirmDialog
    },
    mounted() {
        this.getVariablesExternas();
    },
    watch: {},
    methods: {
        filterVariablesExternas(object) {
            let result = [];
            let index = 0;
            for (let key in object) {
                result.push({
                    index: index,
                    label: key,
                    key: object[key]
                });
                index++;
            }
            return result;
        },
        getVariablesExternas() {
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getFileJsonUrl(
                    'externos',
                    '',
                    'extVars_metaData.json'
                ),
                headers: dict_auth
            };

            Vue.axios(config).then(response => {
                this.variablesExternas = this.filterVariablesExternas(
                    response.data
                );
            });
        },

        async launchtraining(consumptionType) {
            const isIndustrial = consumptionType === 'IND';
            const isDomestic = consumptionType === 'DOM';
            var textDialog;
            var tiempoDialog;
            if (isIndustrial) {
                textDialog =
                    '¿Iniciar entrenamiento modelo Industrial? (seguimiento del cálculo en pestaña de Estado Cálculos)';
                tiempoDialog = 'Confirma la Operación (duración mínima 15 minutos)';
            } else if (isDomestic) {
                textDialog =
                    '¿Iniciar entrenamiento modelo Doméstico? (seguimiento del cálculo en pestaña de Estado Cálculos)';
                tiempoDialog = 'Confirma la Operación (duración mínima 6 minutos)';
            }

            if (
                await this.$refs.confirmTraining.open(tiempoDialog, textDialog)
            ) {
                if (isIndustrial) this.calculating.ind = true;
                else if (isDomestic) this.calculating.dom = true;

                const mergedObject = {
                    variables: this.variableExternaSeleccionada,
                    fechas: this.dates
                };

                let params = JSON.stringify(mergedObject);
                //console.log(params);

                let token = this.$store.getters['getTokenHeader']
                let dict_auth = {
                  'Authorization': token,
                  'Content-Type': "application/json"
                };
                dict_auth['Content-Type'] = 'application/json';
                var config = {
                    method: 'post',
                    url: url_utils.getTrainModelUrl(
                        this.trainDataListSelected[0].label,
                        this.sufijoModeloEntrenado,
                        this.trainDataListSelected[0].consumptionType.toLowerCase()
                    ),
                    headers: dict_auth,
                    params: { params }
                };

                Vue.axios(config).then(response => {
                    if (response.status == 200) {
                        if (isIndustrial) {
                            setTimeout(
                                () => (this.calculating.ind = false),
                                90000
                            );
                        } else if (isDomestic) {
                            setTimeout(
                                () => (this.calculating.dom = false),
                                90000
                            );
                        }
                    } else {
                        if (isIndustrial) this.calculating.ind = false;
                        else if (isDomestic) this.calculating.dom = false;
                    }
                });
            }
        },
        tabclick() {
            this.loadingData = true;
            setTimeout(() => (this.loadingData = false), 1000);
        },
        onsourceLoad() {
            this.loadingData = false;
        }
    }
};
</script>

<style lang="less">
.header-row {
    align-items: center;
}
.hero-image {
    background-position-y: -35px;
}

#app,
#app iframe {
    height: 100vh;
    width: 100vw;
    border: none;
    box-sizing: border-box;
}
body {
    margin: 0;
}
</style>
