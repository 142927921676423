<template>
    <div>
        <v-container fluid>
            <v-row align="start" justify="center">
                <br />
                <br />
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="left">
                    <label
                        style="color: black; padding-left: 10px"
                        class="site-title"
                        ><b>Informes Modelos Entrenados</b></label
                    >
                    <v-dialog
                        v-model="infoInformesModelosEntrenadosVisible"
                        width="500"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                                >mdi-information</v-icon
                            >
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                Informes Modelos Entrenados
                            </v-card-title>
                            <v-card-text>
                                Al seleccionar un modelo de la tabla de modelos
                                entrenados disponibles podrá visualizar los
                                siguientes informes: A) Informe de validación:
                                resumen detallado de la precisión obtenida por
                                el modelo en el data set de validación. B)
                                Gestión de validación: resultados gráficos de
                                comparación de las predicciones del modelo y el
                                consumo real obtenidos en el data set de
                                validación. C) Log entrenamiento: informe
                                técnico del entrenamiento del modelo
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="#004571"
                                    text
                                    @click="
                                        infoInformesModelosEntrenadosVisible = false
                                    "
                                >
                                    Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
                <v-col cols="10" sm="12" md="12" lg="12" xl="12">
                    <ModelList
                        v-model="model"
                        :enabledDialog="false"
                    ></ModelList>
                </v-col>
            </v-row>
            <v-row align="start" justify="center">
                <v-col md="10">
                    <v-tabs align-with-title v-if="selectedmodelname !== null">
                        <v-tab href="#zero" @click="tabclick"
                            >Informe de validación</v-tab
                        >
                        <v-tab href="#one" @click="tabclick"
                            >Gestión de validación</v-tab
                        >
                        <v-tab href="#two" @click="tabclick"
                            >Log Entrenamiento</v-tab
                        >
                        <v-tab-item value="zero">
                            <br />
                            <v-data-table
                                :hide-default-footer="true"
                                :loading="loadingData"
                                loading-text="Cargando datos..."
                            >
                                <template slot="no-data">
                                    <div></div>
                                </template>
                            </v-data-table>
                            <iframe
                                :src="sourceInformeValidacionModelo"
                                style="border: 0; width: 100%; overflow: auto"
                                allowfullscreen="true"
                                id="iframeResizer0"
                                scrolling="yes"
                                @load="onsourceLoad"
                            ></iframe>
                        </v-tab-item>
                        <v-tab-item value="one">
                            <br />
                            <v-data-table
                                :hide-default-footer="true"
                                :loading="loadingData"
                                loading-text="Cargando datos..."
                            >
                                <template slot="no-data">
                                    <div></div>
                                </template>
                            </v-data-table>
                            <TrainImagesValidation
                                :showButton="false"
                                :modelName="selectedmodelname"
                            ></TrainImagesValidation>
                        </v-tab-item>
                        <v-tab-item value="two">
                            <br />
                            <v-data-table
                                :hide-default-footer="true"
                                :loading="loadingData"
                                loading-text="Cargando datos..."
                            >
                                <template slot="no-data">
                                    <div></div>
                                </template>
                            </v-data-table>
                            <iframe
                                :src="sourceInformeModeloEntrenado"
                                style="border: 0; width: 100%; overflow: auto"
                                allowfullscreen="true"
                                id="iframeResizer1"
                                scrolling="yes"
                                @load="onsourceLoad"
                            ></iframe>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ModelList from '@/components/ModelList.vue';
import { mapGetters } from 'vuex';
import TrainImagesValidation from '@/components/TrainImagesValidation';
import url_utils from '../helpers/url_utils';

Vue.use(VueAxios, axios);

export default {
    name: 'Tab4',
    data() {
        return {
            model: {
                selected: null,
                metadata: null
            },
            loadingData: false,
            infoInformesModelosEntrenadosVisible: false
        };
    },
    computed: {
        ...mapGetters(['getTrainMetaData']),
        trainData() {
            return this.getTrainMetaData;
        },
        sourceInformeModeloEntrenado() {
            if (
                this.selectedmodelname !== null ||
                this.selectedmodelname !== undefined
            ) {
                return url_utils.getHtmUrl(
                    'models',
                    this.selectedmodelname,
                    'ReportEntrenamiento.html'
                );
            } else {
                return '';
            }
        },
        sourceInformeValidacionModelo() {
            if (
                this.selectedmodelname !== null ||
                this.selectedmodelname !== undefined
            ) {
                return url_utils.getHtmUrl(
                    'models',
                    this.selectedmodelname,
                    'report_validation.html'
                );
            } else {
                return '';
            }
        },
        selectedmodelname() {
            if (this.model.selected === null) return null;
            else return this.model.selected.label;
        }
    },
    components: {
        ModelList,
        TrainImagesValidation
    },
    mounted() {},
    watch: {
        selectedmodelname: function() {
            this.loadingData = true;
            setTimeout(() => (this.loadingData = false), 1000);
        }
    },
    methods: {
        tabclick() {
            this.loadingData = true;
            setTimeout(() => (this.loadingData = false), 1000);
        },
        onsourceLoad() {
            this.loadingData = false;
        }
    }
};
</script>

<style lang="less">
.header-row {
    align-items: center;
}
.hero-image {
    background-position-y: -35px;
}

#app,
#app iframe {
    height: 100vh;
    width: 100vw;
    border: none;
    box-sizing: border-box;
}
body {
    margin: 0;
}
</style>
