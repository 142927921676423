<template>
    <v-container fluid fill-height>
        <v-layout child-flex>
            <v-row align="start" justify="space-between">
                <v-col cols="5">
                    <v-layout column style="height: 130vh">
                        <v-flex md7 style="overflow: scroll">
                            <v-data-table
                                :headers="headers"
                                :items="tableList"
                                :hide-default-footer="false"
                                fixed-header
                                disable-sort
                                :items-per-page="14"
                                class="elevation-3"
                                :loading="loading"
                                loading-text="Cargando datos... "
                                :footer-props="{
                                  showFirstLastPage: true
                                }"
                            >
                                <template v-slot:body="{ items }">
                                    <tbody>
                                        <tr
                                            v-for="(item, index) in items"
                                            :key="index"
                                            :class="{
                                                'primary lighten-5':
                                                    selectedImageIndex === index
                                            }"
                                            @click="selectedImageIndex = index"
                                        >
                                            <td
                                                class="overflow-row-attempt overflow-row-visible text-center"
                                            >
                                                {{ item.url }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                        </v-flex>
                    </v-layout>
                </v-col>
                <v-col cols="7">
                    <v-window class="elevation-3" style="margin-bottom: 2%">
                        <v-row
                            align="center"
                            justify="space-around"
                            v-if="
                                pngList.length !== 0 &&
                                    selectedImageIndex !== null
                            "
                        >
                            <ImagesThumbnail
                                :imageName="getSelectedItem.name"
                                :imageURL="getSelectedItem.url"
                                :showButton="false"
                                :modelName="modelName"
                            ></ImagesThumbnail>
                        </v-row>
                    </v-window>
                </v-col>
                <!--    This is to add more space between image and footer    -->
                <v-col cols="12">
                    <br />
                    <br />
                    <br />
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>
import Vue from 'vue';
import ImagesThumbnail from '@/components/ImagesThumbnail';
import url_utils from '../helpers/url_utils';

export default {
    name: 'TrainImagesValidation',
    props: ['showButton', 'modelName'],
    components: { ImagesThumbnail },
    data() {
        return {
            dialog: this.value,
            headers: [
                { text: 'Url', value: 'url', align: 'left', sortable: false }
            ],
            pngList: [],
            loading: false,
            name: null,
            selectedImageIndex: null,
            initpng: false
        };
    },
    computed: {
        tableList: function() {
            if (this.initpng == false) {
                this.getPNGList();
            }
            return this.pngList;
        },
        getSelectedItem: function() {
            if (
                this.pngList.length === 0 ||
                this.pngList === null ||
                this.selectedImageIndex === null
            ) {
                return null;
            } else {
                return this.pngList[this.selectedImageIndex];
            }
        }
    },
    methods: {
        getPNGList() {
            this.loading = true;
            this.initpng = true;

            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };

            var config = {
                method: 'get',
                url: url_utils.getListModelValidationFilesUrl(this.modelName),
                headers: dict_auth
            };

            /* Fastapi
            Vue.axios
                .get(
                    'http://localhost:8000/api/png/all/'.concat(this.modelName)
                )
            */

            Vue.axios(config)
                .then(response => {
                    if (
                        response.data.filelist === null ||
                        response.status !== 200
                    ) {
                        this.pngList = [];
                    } else {
                        /* Fastapi
                        this.pngList = response.data
                        */
                        this.pngList = response.data.filelist;
                        this.selectedImageIndex = 0;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    console.log('Response error: '.concat(error));
                    this.loading = false;
                });
        }
    },
    watch: {
        modelName: function() {
            if (this.modelName !== null) {
                this.getPNGList();
            }
        }
    }
};
</script>

<style scoped></style>
