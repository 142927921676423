<template>
    <v-container fluid>
        <v-row align="center" justify="start">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="receivedList"
                    :single-select="true"
                    item-key="index"
                    class="elevation-1"
                    :loading="loadingData"
                    loading-text="Cargando datos... "
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                >
                    <v-progress-linear
                        v-show="loadingData"
                        slot="progress"
                        color="#004571"
                        indeterminate
                    ></v-progress-linear>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import url_utils from '../helpers/url_utils';

Vue.use(VueAxios, axios);

export default {
    name: 'TrainDataListView',
    props: [],
    data() {
        return {
            loadingData: false,
            receivedList: undefined,
            headers: [
                {
                    text: 'Label',
                    align: 'start',
                    sortable: false,
                    value: 'label'
                },
                {
                    text: 'Identificador Datos',
                    align: 'start',
                    sortable: false,
                    value: 'iddatos'
                },
                {
                    text: 'Tipo Consumo',
                    align: 'start',
                    sortable: false,
                    value: 'consumptionType'
                },
                /*
                {
                    text: 'Estructura Entrenamiento',
                    align: 'start',
                    sortable: false,
                    value: 'dataStruct'
                },
                */
                {
                    text: 'Fecha Creación',
                    align: 'start',
                    sortable: false,
                    value: 'creationdate'
                },
                {
                    text: 'Fechas Incluidas',
                    align: 'start',
                    sortable: false,
                    value: 'includedDates'
                },
                {
                    text: 'Periodo Entrenamiento',
                    align: 'start',
                    sortable: false,
                    value: 'train_period'
                },
                {
                    text: 'Periodo Validación',
                    align: 'start',
                    sortable: false,
                    value: 'validation_period'
                },
                {
                    text: 'Periodo Predicción',
                    align: 'start',
                    sortable: false,
                    value: 'prediction_period'
                },
                {
                    text: 'Automático',
                    align: 'start',
                    sortable: false,
                    value: 'auto'
                },
                {
                    text: 'Resultado',
                    align: 'start',
                    sortable: false,
                    value: 'resultado'
                }
            ],
            sortBy: 'label',
            sortDesc: true
        };
    },
    mounted() {
        setTimeout(this.executeRefresh.bind(this), 1000);
    },
    methods: {
        executeRefresh() {
            this.getTrainListView();
            setTimeout(this.executeRefresh.bind(this), 60000);
        },
        getTrainListView() {
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getReturnMetadataSummaryUrl(
                    'train',
                    'creationDate'
                ),
                headers: dict_auth
            };

            this.loadingData = true;
            Vue.axios(config).then(response => {
                function ModelItem(
                    index,
                    label,
                    preprocessedData,
                    creationDate,
                    consumptionType,
                    dataStruct,
                    trainHorizon,
                    includedDates,
                    train_period,
                    validation_period,
                    prediction_period,
                    auto,
                    resultado
                ) {
                    this.index = index;
                    this.label = label;
                    this.iddatos = preprocessedData;
                    this.consumptionType = consumptionType;
                    this.dataStruct = dataStruct;
                    this.trainHorizon = trainHorizon;
                    this.includedDates = includedDates;
                    this.creationdate = creationDate;
                    this.train_period = train_period;
                    this.validation_period = validation_period;
                    this.prediction_period = prediction_period;
                    this.auto = auto;
                    this.resultado = resultado;
                }

                var itemList = [];
                for (var index in response.data.metadatas) {
                    var estadoProceso;
                    if (response.data.metadatas[index].inProcess == true)
                        estadoProceso = 'Procesando';
                    else estadoProceso = 'Ok';

                    var automatico;
                    if (response.data.metadatas[index].auto == true)
                        automatico = 'Sí';
                    else automatico = 'No';

                    itemList.push(
                        new ModelItem(
                            index,
                            response.data.metadatas[index].dirLabel,
                            response.data.metadatas[index].preprocessedData,
                            response.data.metadatas[index].creationDate,
                            response.data.metadatas[index].consumptionType,
                            response.data.metadatas[index].dataStruct,
                            response.data.metadatas[index].trainHorizon,
                            response.data.metadatas[index].includedDates[0] +
                                ' - ' +
                                response.data.metadatas[index].includedDates[1],
                            response.data.metadatas[index].train_period[0] +
                                ' - ' +
                                response.data.metadatas[index].train_period[1],
                            response.data.metadatas[index]
                                .validation_period[0] +
                                ' - ' +
                                response.data.metadatas[index]
                                    .validation_period[1],
                            response.data.metadatas[index].dateIniPred +
                                ' - ' +
                                response.data.metadatas[index].dateFinPred,
                            automatico,
                            estadoProceso
                        )
                    );
                }

                //console.log(itemList);

                this.receivedList = itemList;
                this.loadingData = false;
            });
        }
    },
    watch: {}
};
</script>
