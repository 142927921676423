<template>
    <div class="home">
        <v-tabs color="#004571" align-with-title>
            <v-tabs-slider color="#004571"></v-tabs-slider>
            <v-tab href="#zero">Predicción Combinada</v-tab>
            <v-tab-item value="zero">
                <Tab0></Tab0>
            </v-tab-item>
            <v-tab v-if="isFull" href="#one"
                >Combinar Industrial+Domestico</v-tab
            >
            <v-tab-item value="one">
                <Tab1></Tab1>
            </v-tab-item>
            <v-tab default href="#two">Consultar Predicción</v-tab>
            <v-tab-item value="two">
                <Tab2></Tab2>
            </v-tab-item>
            <v-tab v-if="isFull" href="#three">Crear Predicción</v-tab>
            <v-tab-item value="three">
                <Tab3></Tab3>
            </v-tab-item>
            <v-tab href="#four">Consultar Modelos</v-tab>
            <v-tab-item value="four">
                <Tab4></Tab4>
            </v-tab-item>
            <v-tab v-if="isFull" href="#five">Entrenar Modelo</v-tab>
            <v-tab-item value="five">
                <Tab5></Tab5>
            </v-tab-item>
            <v-tab href="#six">Preprocesar Datos</v-tab>
            <v-tab-item value="six">
                <Tab6></Tab6>
            </v-tab-item>
            <v-tab v-if="isFull" href="#seven">Estado Cálculos</v-tab>
            <v-tab-item value="seven">
                <ProcesosCalculo></ProcesosCalculo>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
// @ is an alias to /src
import Tab0 from '@/components/Tab0.vue';
import Tab1 from '@/components/Tab1.vue';
import Tab2 from '@/components/Tab2.vue';
import Tab3 from '@/components/Tab3.vue';
import Tab4 from '@/components/Tab4.vue';
import Tab5 from '@/components/Tab5.vue';
import Tab6 from '@/components/Tab6.vue';
import ProcesosCalculo from '@/components/ProcesosCalculo.vue';
import utils from '@/helpers/utils';

export default {
    name: 'Home',
    data() {
        return {
        };
    },
    computed: {
        isFull() {
            return utils.isInterfaceComplete();
        }
    },
    components: {
        Tab0,
        Tab1,
        Tab2,
        Tab3,
        Tab4,
        Tab5,
        Tab6,
        ProcesosCalculo
    }
};
</script>
