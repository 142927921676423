const BASE_URL = 'https://2hl6toeyk5.execute-api.eu-west-1.amazonaws.com/stagepro';

export default {
    apiUrl() {
        return BASE_URL;
    },
    getReturnMetadataSummaryUrl(categoryname, mainidentifier) {
        return this.apiUrl()
            .concat('/previdem-return-metadatasummary')
            .concat('?categoryname=')
            .concat(categoryname)
            .concat('&mainidentifier=')
            .concat(mainidentifier);
    },
    getHtmUrl(category, subcategory, reportname) {
        return this.apiUrl()
            .concat('/previdem-get-htm')
            .concat('?category=')
            .concat(category)
            .concat('&subcategory=')
            .concat(subcategory)
            .concat('&reportname=')
            .concat(reportname);
    },
    getPredictionexcelFileUrl(predictionname, name) {
        return this.apiUrl()
            .concat('/previdem-predictionexcel-file')
            .concat('?predictionname=')
            .concat(predictionname)
            .concat('&filename=')
            .concat(name);
    },
    getDownloadFileUrl(category, subcategory, name) {
        return this.apiUrl()
            .concat('/previdem-download-file')
            .concat('?category=')
            .concat(category)
            .concat('&subcategory=')
            .concat(subcategory)
            .concat('&filename=')
            .concat(name);
    },
    getProcessmodelUrl(mode) {
        return this.apiUrl()
            .concat('/previdem-processmodel')
            .concat('?mode=')
            .concat(mode);
    },
    getModelValidationFileUrl(modelname, imageUrl) {
        return this.apiUrl()
            .concat('/previdem-model-validation-file')
            .concat('?modelname=')
            .concat(modelname)
            .concat('&filename=')
            .concat(imageUrl);
    },
    getListPrevisionCombinedUrl() {
        return this.apiUrl().concat('/previdem-listprevisioncombined');
    },
    getPredictionUrl(predictionfolder, tipoConsumo) {
        return this.apiUrl()
            .concat('/previdem-prediction')
            .concat('?predictionfolder=')
            .concat(predictionfolder)
            .concat('&tipoconsumo=')
            .concat(tipoConsumo);
    },
    getCombinationUrl(labelIndustrial, labelDomestico) {
        return this.apiUrl()
            .concat('/previdem-combination')
            .concat('?indfolder=')
            .concat(labelIndustrial)
            .concat('&domfolder=')
            .concat(labelDomestico);
    },
    getTrainModelUrl(traindatalabel, modellabel, tipoconsumo) {
        return this.apiUrl()
            .concat('/previdem-trainmodel')
            .concat('?traindatalabel=')
            .concat(traindatalabel)
            .concat('&modellabel=')
            .concat(modellabel)
            .concat('&tipoconsumo=')
            .concat(tipoconsumo);
    },
    getDataprocessedCsvPresignedFileUrl(modelname, filename) {
        return this.apiUrl()
            .concat('/previdem-dataprocessedcsv-presignedfile')
            .concat('?modelname=')
            .concat(modelname)
            .concat('&filename=')
            .concat(filename);
    },
    getDataprocessedCsvAllUrl(modelname) {
        return this.apiUrl()
            .concat('/previdem-dataprocessedcsv-all')
            .concat('?modelname=')
            .concat(modelname);
    },
    getListModelValidationFilesUrl(modelname) {
        return this.apiUrl()
            .concat('/previdem-listmodel-validation-files')
            .concat('?modelname=')
            .concat(modelname);
    },
    getListFilesUrl(category, subcategory) {
        return this.apiUrl()
            .concat('/previdem-listfiles')
            .concat('?category=')
            .concat(category)
            .concat('&subcategory=')
            .concat(subcategory);
    },
    getFileJsonUrl(category, subcategory, filename) {
        return this.apiUrl()
            .concat('/previdem-get-json')
            .concat('?category=')
            .concat(category)
            .concat('&subcategory=')
            .concat(subcategory)
            .concat('&filename=')
            .concat(filename);
    }
};
