<template>
    <div>
        <v-container fluid fill-height>
            <v-row align="center" justify="center">
                <v-col md="6">
                    <PredictionsCombinedList
                        v-model="prediccion"
                    ></PredictionsCombinedList>
                </v-col>
                <v-col cols="12">
                    <v-tabs
                        align-with-title
                        v-if="prediccion.selected !== null"
                    >
                        <v-tab href="#zero">Resultados Predicción</v-tab>
                        <v-tab href="#one" @click="tabclick"
                            >Informe Predicción Industrial</v-tab
                        >
                        <v-tab href="#two" @click="tabclick"
                            >Informe Predicción Doméstico</v-tab
                        >
                        <v-tab-item value="zero">
                            <v-row align="center" justify="center">
                                <br />
                                <v-col>
                                    <v-btn
                                        color="#004571"
                                        class="ma-2 white--text"
                                        @click="downloadUrl('resumen_py.xlsx')"
                                    >
                                        Descargar Resumen
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center">
                                <v-col>
                                    <v-btn
                                        color="#004571"
                                        class="ma-2 white--text"
                                        @click="
                                            downloadUrl(
                                                'explicabilidad_py.xlsx'
                                            )
                                        "
                                    >
                                        Descargar Explicabilidad
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item value="one">
                            <br />
                            <v-data-table
                                :hide-default-footer="true"
                                :loading="loadingData"
                                loading-text="Cargando datos..."
                            >
                                <template slot="no-data">
                                    <div></div>
                                </template>
                            </v-data-table>
                            <iframe
                                :src="sourceInformePrevisionIndustrial"
                                style="border: 0; width: 100%; overflow: auto"
                                allowfullscreen="true"
                                id="iframeResizer1"
                                scrolling="yes"
                                @load="onsourceLoad"
                            ></iframe>
                        </v-tab-item>
                        <v-tab-item value="two">
                            <br />
                            <v-data-table
                                :hide-default-footer="true"
                                :loading="loadingData"
                                loading-text="Cargando datos..."
                            >
                                <template slot="no-data">
                                    <div></div>
                                </template>
                            </v-data-table>
                            <iframe
                                :src="sourceInformePrevisionDomestico"
                                style="border: 0; width: 100%; overflow: auto"
                                allowfullscreen="true"
                                id="iframeResizer1"
                                scrolling="yes"
                                @load="onsourceLoad"
                            ></iframe>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);
import PredictionsCombinedList from '@/components/PredictionsCombinedList.vue';
import utils from '@/helpers/utils';
import url_utils from '@/helpers/url_utils';

export default {
    name: 'Tab0',
    data() {
        return {
            calculating: false,
            model: {
                selected: null,
                metadata: null
            },
            prediccion: {
                selected: null
            },
            predictionDataModified: false,
            loadingData: false
        };
    },
    computed: {
        selectedmodelcomputed() {
            if (this.model.selected === null) return null;
            else {
                this.setPredictionDataModified(false);
                return this.model.selected.modelname;
            }
        },
        selectedpredictioncomputed() {
            if (this.prediccion.selected === null) return null;
            else return this.prediccion.selected.modelname;
        },
        sourceInformePrevisionIndustrial() {
            if (
                this.selectedpredictioncomputed !== null ||
                this.selectedpredictioncomputed !== undefined
            ) {
                return url_utils.getHtmUrl(
                    'predictioncombined',
                    this.selectedpredictioncomputed,
                    'Industrial_Prediction_Report.html'
                );
            } else {
                return '';
            }
        },
        sourceInformePrevisionDomestico() {
            if (
                this.selectedpredictioncomputed !== null ||
                this.selectedpredictioncomputed !== undefined
            ) {
                return url_utils.getHtmUrl(
                    'predictioncombined',
                    this.selectedpredictioncomputed,
                    'Comercial_Prediction_Report.html'
                );
            } else {
                return '';
            }
        }
    },
    components: {
        PredictionsCombinedList
    },
    mounted() {},
    watch: {},
    methods: {
        setPredictionDataModified(value) {
            this.predictionDataModified = value;
        },
        downloadUrl(name) {
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getPredictionexcelFileUrl(
                    this.selectedpredictioncomputed,
                    name
                ),
                headers: dict_auth
            };
            Vue.axios(config)
                .then(response => {
                    const url = response.data;
                    utils.downloadFromUrl(url);
                })
                .catch(error => {
                    console.log('Error');
                    console.log(error.message);
                });
        },
        tabclick() {
            this.loadingData = true;
            setTimeout(() => (this.loadingData = false), 1000);

            // Printing the user environment object
            // console.log(location.href);
        },
        onsourceLoad() {
            this.loadingData = false;
        }
    }
};
</script>

<style lang="less">
.header-row {
    align-items: center;
}

.hero-image {
    background-position-y: -35px;
}

#app,
#app iframe {
    height: 100vh;
    width: 100vw;
    border: none;
    box-sizing: border-box;
}

body {
    margin: 0;
}
</style>
