<template>
    <v-footer relative app width="100%">
        <v-card
            flat
            tile
            width="100%"
            class="flex lighten-1 white--text text-center"
            color="#004571"
        >
            <v-card-text class="white--text">
                {{ new Date().getFullYear() }} —
                <strong>ForGAS v1.0.20220518</strong>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    data: () => ({
        icons: []
    })
};
</script>
