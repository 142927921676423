<template>
    <v-container fluid>
        <v-row align="center" justify="center">
            <v-col cols="10">
                <v-subheader>Procesos de Cálculo en Curso</v-subheader>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col cols="10">
                <v-data-table
                    :headers="headers"
                    :items="receivedList"
                    item-key="index"
                    class="elevation-1"
                    :loading="loadingData"
                    loading-text="Cargando datos... "
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                >
                    <v-progress-linear
                        v-show="loadingData"
                        slot="progress"
                        color="#004571"
                        indeterminate
                    ></v-progress-linear>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import url_utils from '../helpers/url_utils';

Vue.use(VueAxios, axios);

export default {
    name: 'ProcesosCalculo',
    props: ['value'],
    components: {},
    data() {
        return {
            receivedList: [],
            loadingData: false,
            dialog: false,
            temporalModel: null,
            model: this.value,
            headers: [
                {
                    text: 'Proceso',
                    align: 'start',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: 'Fecha Creación',
                    align: 'start',
                    sortable: false,
                    value: 'timestamp'
                },
                {
                    text: 'Tiempo Transcurrido',
                    align: 'start',
                    sortable: false,
                    value: 'transcurrido'
                }
            ],
            sortBy: 'transcurrido',
            sortDesc: true
        };
    },
    mounted() {
        setTimeout(this.executeRefresh.bind(this), 1000);
    },
    computed: {},
    methods: {
        executeRefresh() {
            this.getProcessesList();
            setTimeout(this.executeRefresh.bind(this), 20000);
        },
        getProcessesList() {
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getListFilesUrl('status', ''),
                headers: dict_auth
            };

            this.loadingData = true;
            Vue.axios(config).then(response => {

                function ProcessItem(index, name, timestamp, transcurrido) {
                    this.index = index;
                    this.name = name;
                    this.timestamp = timestamp;
                    this.transcurrido = transcurrido;
                }

                var itemList = [];
                for (var index in response.data.processes) {
                    var processName = '';
                    var textDate = '';
                    var mark = response.data.processes[index];

                    if (mark.startsWith('_PREPROCESSING_IND_')) {
                        processName = 'Preprocesado Industrial';
                        textDate = mark.replace('_PREPROCESSING_IND_', '');
                    }

                    if (mark.startsWith('_PREPROCESSING_DOM_')) {
                        processName = 'Preprocesado Doméstico';
                        textDate = mark.replace('_PREPROCESSING_DOM_', '');
                    }

                    if (mark.startsWith('_PREPROCESSING_EXT_')) {
                        processName = 'Preprocesado Variables Externas';
                        textDate = mark.replace('_PREPROCESSING_EXT_', '');
                    }

                    if (mark.startsWith('_TRAINING_IND_')) {
                        processName = 'Entrenar Modelo Industrial';
                        textDate = mark.replace('_TRAINING_IND_', '');
                    }

                    if (mark.startsWith('_TRAINING_DOM_')) {
                        processName = 'Entrenar Modelo Doméstico';
                        textDate = mark.replace('_TRAINING_DOM_', '');
                    }

                    if (mark.startsWith('_PREDICTING_IND_')) {
                        processName = 'Predecir Modelo Industrial';
                        textDate = mark.replace('_PREDICTING_IND_', '');
                    }

                    if (mark.startsWith('_PREDICTING_DOM_')) {
                        processName = 'Predecir Modelo Doméstico';
                        textDate = mark.replace('_PREDICTING_DOM_', '');
                    }

                    if (mark.startsWith('_COMBINING_')) {
                        processName = 'Crear Predicción Combinada';
                        textDate = mark.replace('_COMBINING_', '');
                    }

                    if (textDate != '') {
                        var date = new Date(
                            textDate.substr(0, 4),
                            textDate.substr(4, 2) - 1,
                            textDate.substr(6, 2),
                            textDate.substr(9, 2),
                            textDate.substr(11, 2),
                            textDate.substr(13, 2)
                        );

                        var dateMsec = date.getTime();
                        var interval = Date.now() - dateMsec;
                        var msecPerMinute = 1000 * 60;
                        var msecPerHour = msecPerMinute * 60;
                        var msecPerDay = msecPerHour * 24;

                        // Calcular las horas , minutos y segundos
                        var days = Math.floor(interval / msecPerDay);
                        interval = interval - days * msecPerDay;
                        var hours = Math.floor(interval / msecPerHour);
                        interval = interval - hours * msecPerHour;
                        var minutes = Math.floor(interval / msecPerMinute);
                        interval = interval - minutes * msecPerMinute;
                        var seconds = Math.floor(interval / 1000);
                        var transcurrido =
                            days +
                            ' dias ' +
                            hours +
                            ' horas ' +
                            minutes +
                            ' minutos ' +
                            seconds +
                            ' segundos';

                        itemList.push(
                            new ProcessItem(
                                index,
                                processName,
                                date,
                                transcurrido
                            )
                        );
                    }
                }

                this.receivedList = itemList;
                this.loadingData = false;
            });
        },
        setSelectedModel(setNewValue) {
            if (setNewValue === false) {
                this.setModelSelected(this.temporalModel);
            } else {
                this.selectmodel(this.model.selected);
            }
        },
        getTime(offset) {
            var d = new Date();
            var localTime = d.getTime();
            var localOffset = d.getTimezoneOffset() * 60000;

            // obtain UTC time in msec
            var utc = localTime + localOffset;
            // create new Date object for different city
            // using supplied offset
            var nd = new Date(utc + 3600000 * offset);
            return nd;
        }
    }
};
</script>
