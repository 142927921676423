<template>
    <v-row align="center" justify="center">
        <v-col cols="12" v-if="img !== null">
            <v-card>
                <v-card-title class="text-h6" style="margin-bottom: -3%">
                    <v-col cols="12" align="center" justify="center">
                        {{ imageURL }}
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <div>
                        <img class="full" :src="getImg" alt="Waiting image" />
                    </div>
                </v-card-text>
                <v-card-actions style="margin-top: -2%">
                    <v-col cols="12" align="center" justify="center">
                        <v-btn
                            color="blue darken-1"
                            text
                            :disabled="img === null"
                            @click="downloadImage"
                        >
                            <b>Download</b>
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import Vue from 'vue';
import utils from '@/helpers/utils';
import url_utils from '../helpers/url_utils';

export default {
    name: 'ImagesThumbnail',
    props: ['showButton', 'imageURL', 'imageName', 'modelName'],
    data() {
        return {
            loading: false,
            img: null,
            urlCreator: window.URL || window.webkitURL
        };
    },
    mounted() {
        // Needed to load first time component is created
        this.getPNGImage();
    },
    computed: {
        getImg() {
            if (this.img === null) return null;
            return this.urlCreator.createObjectURL(this.img);
        }
    },
    methods: {
        downloadImage() {
            if (this.img !== null) {
                utils.downloadImage(this.img, this.imageName);
            }
        },
        getPNGImage() {
            this.loading = true;
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json",
              'Accept': "image/png"
            };
            var config = {
                method: 'get',
                url: url_utils.getModelValidationFileUrl(
                    this.modelName,
                    this.imageURL
                ),
                responseType: 'blob',
                headers: dict_auth
            };

            /* Fastapi
            let config = {
                responseType: 'blob',
                params: { image_url: this.imageURL }
            };
            Vue.axios
                .get('http://localhost:8000/api/png/find/', config)
            */

            Vue.axios(config)
                .then(response => {
                    this.img = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    console.log('Response error: '.concat(error));
                    this.img = null;
                    this.loading = false;
                });
        }
    },
    watch: {
        imageURL: function() {
            this.getPNGImage();
        }
    }
};
</script>

<style scoped></style>
