import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import About from '../views/About';
import SignIn from '../components/auth/SignIn';
import ConfirmPassword from '../components/auth/ConfirmPassword';
import {store} from '../store/index'

Vue.use(VueRouter);

function isNeededSetNewPassword() {
    return store.getters['getNeededChangePassword'];
}

function isUsserLogged() {
  return store.getters['isUserAuthenticated'];
}

const routes = [
  {
    path: '/'
  },
  {
    path: '/login',
    name: 'Login',
    component: SignIn
  },
  {
    path: '/confirm',
    name: 'Confirm',
    component: ConfirmPassword
  },
  {
    path: '/home',
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    return next({name: "Login"});
  }
  if (to.path == "/confirm") {
    if (!isNeededSetNewPassword()){
      return next({name: "Login"});
    }
    else {
      return next()
    }
  }
  let publicPages = ['/login'];
  let authRequired = !publicPages.includes(to.path);
  let loggedIn = isUsserLogged();
  if (authRequired) {
    if(!loggedIn) {
      return next({name: 'Login', query: {next: to.path}});
    }
  }
  return next();
})
