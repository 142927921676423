<template>
    <v-container fluid>
        <v-row align="center">
            <v-col cols="3">
                <v-subheader>Predicciones Combinadas Disponibles</v-subheader>
            </v-col>
            <v-col cols="9">
                <v-select
                    :items="receivedList"
                    item-text="modelname"
                    item-value="index"
                    label="Escoge Predicción"
                    persistent-hint
                    return-object
                    single-line
                    :value="$store.selecteddata"
                    @input="selectedprevision"
                ></v-select>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import url_utils from '../helpers/url_utils';

Vue.use(VueAxios, axios);

export default {
    name: 'PredictionsCombinedList',
    props: ['value'],
    data() {
        return { receivedList: undefined };
    },
    mounted() {
        setTimeout(this.executeRefresh.bind(this), 1000);
    },
    methods: {
        executeRefresh() {
            this.getCombinedList();
            setTimeout(this.executeRefresh.bind(this), 60000);
        },
        getCombinedList() {
            let url = url_utils.getListPrevisionCombinedUrl();
            let token = this.$store.getters['getTokenHeader']
            let headers = {
              'Content-Type': 'application/json',
              'Authorization': token
            };
            var config = {
                method: 'get',
                url: url,
                headers: headers
            };

            Vue.axios(config).then(response => {
                function ModelItem(index, modelname) {
                    this.index = index;
                    this.modelname = modelname;
                }

                var itemList = [];
                for (var index in response.data.models) {
                    itemList.push(
                        new ModelItem(index, response.data.models[index])
                    );
                }

                this.receivedList = itemList;
            });
        },
        selectedprevision(val) {
            this.value.selected = val;
        }
    }
};
</script>
