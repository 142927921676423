import {store} from '../store/index'

export default {
    download(text, name, type) {
        const url = window.URL.createObjectURL(
            new Blob([text], { type: type })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    },
    downloadFromUrl(url) {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
    },
    downloadCSV(text, name) {
        this.download(text, name, 'text/csv');
    },
    downloadEXCEL(text, name) {
        this.download(text, name, 'text/excel');
    },
    downloadEXCELURL(text, name) {
        this.downloadURL(text, name);
    },
    downloadZIP(text, name) {
        let type = 'application/zip';
        this.download(text, name, type);
    },
    downloadImage(text, name) {
        let type = 'image/png';
        this.download(text, name, type);
    },
    isInterfaceComplete() {
        return store.getters['isUserFull'];
    },
    interfaceEnvironment() {
        if (location.href.indexOf('pro') !== -1) return 'pro';
        else return 'dev';
    }
};
