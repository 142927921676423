const state = {
    // Keeps the selected model to work with
    selectedmodel: '',
    selectedtrainmetadata: null,
    selecteddata: '',
    tipomodeloentrenamiento: -1,
    horizontetemporalentrenamiento: -1,
    modelmetadata: undefined,
    selectedvariable: '',
    selectedrange: ''
};

const mutations = {
    SELECTMODEL(state, modelselected) {
        state.selectedmodel = modelselected;
    },
    SELECTTRAINMETADATA(state, modelmetadataselected) {
        state.selectedtrainmetadata = modelmetadataselected;
    },
    MODELMETADATA(state, modelmetadata) {
        state.modelmetadata = modelmetadata;
    },
    SELECTDATAPROCESSED(state, selecteddata) {
        state.selecteddata = selecteddata;
    },
    SELECTEDVARIABLE(state, selectedvariable) {
        state.selectedvariable = selectedvariable;
    },
    SELECTEDRANGE(state, selectedrange) {
        state.selectedrange = selectedrange;
    }
};

const actions = {
    selectmodel(state, modelselected) {
        state.commit('SELECTMODEL', modelselected);
    }
};

const getters = {
    getselectedmodel(state) {
        return state.selectedmodel;
    },
    getParametricFeatures: state => {
        if (state.modelmetadata == undefined) {
            return ['Pending Model Selection'];
        } else {
            return state.modelmetadata.parametrical_features;
        }
    },
    getModelMetadata: state => {
        return state.modelmetadata;
    },
    getTrainMetaData: state => {
        return state.selectedtrainmetadata;
    }
};

export const model = {
    state,
    mutations,
    actions,
    getters
};
