<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="350">
            <v-card>
                <v-card-title class="text-h5">
                    Confirmación
                </v-card-title>
                <v-card-text>
                    Quiere cambiar el elemento seleccionado?, si lo hace perderá
                    los cambios realizados.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="cancel">
                        Cancelar
                    </v-btn>
                    <v-btn color="green darken-1" text @click="confirm">
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    name: 'ConfirmationModelListDialog',
    props: ['value'],
    data() {
        return {
            dialog: this.value
        };
    },
    methods: {
        confirm() {
            this.$emit('setNewValue', true);
            this.closeDialog();
        },
        cancel() {
            this.$emit('setOldValue', false);
            this.closeDialog();
        },
        closeDialog() {
            this.$emit('input', false);
            this.dialog = false;
        }
    },
    watch: {
        value: function() {
            this.dialog = this.value;
        }
    }
};
</script>

<style scoped></style>
