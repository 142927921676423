<template>
    <v-container fluid>
        <v-row align="center">
            <v-col cols="12">
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="receivedList"
                    :single-select="true"
                    item-key="iddatos"
                    show-select
                    class="elevation-1"
                    @input="enterSelect()"
                    :loading="loadingData"
                    loading-text="Cargando datos... "
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                >
                    <v-progress-linear
                        v-show="loadingData"
                        slot="progress"
                        color="#004571"
                        indeterminate
                    ></v-progress-linear>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import url_utils from '../helpers/url_utils';

Vue.use(VueAxios, axios);

export default {
    name: 'DataList',
    props: [],
    data() {
        return {
            loadingData: false,
            receivedList: undefined,
            selected: [],
            selecteddata: null,
            headers: [
                {
                    text: 'Identificador Datos',
                    align: 'start',
                    sortable: false,
                    value: 'iddatos'
                },
                {
                    text: 'Tipo Consumo',
                    align: 'start',
                    sortable: false,
                    value: 'tipoConsumo'
                },
                {
                    text: 'Fecha Creación',
                    align: 'start',
                    sortable: false,
                    value: 'creationdate'
                },
                {
                    text: 'Fecha Inicio',
                    align: 'start',
                    sortable: false,
                    value: 'minDate'
                },
                {
                    text: 'Fecha Final',
                    align: 'start',
                    sortable: false,
                    value: 'maxDate'
                },
                {
                    text: 'Número Registros',
                    align: 'start',
                    sortable: false,
                    value: 'nRegistries'
                },
                {
                    text: 'Automático',
                    align: 'start',
                    sortable: false,
                    value: 'auto'
                },
                {
                    text: 'Resultado',
                    align: 'start',
                    sortable: false,
                    value: 'estadoProceso'
                }
            ],
            sortBy: 'iddatos',
            sortDesc: true
        };
    },
    mounted() {
        setTimeout(this.executeRefresh.bind(this), 1000);
    },
    methods: {
        executeRefresh() {
            this.getDataList();
            setTimeout(this.executeRefresh.bind(this), 60000);
        },
        getDataList() {
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getReturnMetadataSummaryUrl(
                    'preprocessed',
                    'dirLabel'
                ),
                headers: dict_auth
            };

            this.loadingData = true;
            Vue.axios(config).then(response => {
                function ModelItem(
                    index,
                    dirLabel,
                    creationdate,
                    consumptionType,
                    minDate,
                    maxDate,
                    nRegistries,
                    auto,
                    estadoProceso
                ) {
                    this.index = index;
                    this.iddatos = dirLabel;
                    this.tipoConsumo = consumptionType;
                    this.creationdate = creationdate;
                    this.minDate = minDate;
                    this.maxDate = maxDate;
                    this.nRegistries = nRegistries;
                    this.auto = auto;
                    this.estadoProceso = estadoProceso;
                }

                var itemList = [];
                for (var index in response.data.metadatas) {
                    var estadoProceso;
                    if (response.data.metadatas[index].inProcess == true)
                        estadoProceso = 'Procesando';
                    else estadoProceso = 'Ok';

                    var automatico;
                    if (response.data.metadatas[index].auto == true)
                        automatico = 'Sí';
                    else automatico = 'No';

                    itemList.push(
                        new ModelItem(
                            index,
                            response.data.metadatas[index].dirLabel,
                            response.data.metadatas[index].creationDate,
                            response.data.metadatas[index].consumptionType,
                            response.data.metadatas[index].minDate,
                            response.data.metadatas[index].maxDate,
                            response.data.metadatas[index].nRegistries,
                            automatico,
                            estadoProceso
                        )
                    );
                }

                this.receivedList = itemList;
                this.loadingData = false;
            });
        },
        enterSelect() {
            if (this.selected[0] != undefined) {
                this.selecteddata = this.selected[0].iddatos;
                this.$emit('input', this.selecteddata);
            }
            //console.log(this.selected.map(e => e.name)); // logs all the selected items.

            if (this.selected.length == this.itemsPerPage) {
                alert('selected all');
            }
        }
    }
};
</script>
