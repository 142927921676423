<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            transition="dialog-top-transition"
            max-width="600"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    class="ma-2 white--text"
                    color="#004571"
                    v-bind="attrs"
                    v-on="on"
                    >Añadir rango de fechas
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="text-h5">
                    {{ title }}
                </v-card-title>
                <v-card-text>
                    <v-date-picker
                        v-model="dates"
                        elevation="15"
                        width="400"
                        type="month"
                        class="mt-4"
                        range
                    ></v-date-picker>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn color="black" text @click="closeDialog">
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="black"
                        text
                        :disabled="dates.length < 2"
                        @click="addDates"
                    >
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    name: 'DateRangePickerDialog',
    props: ['title'],
    data() {
        return {
            dates: [],
            dialog: false
        };
    },
    methods: {
        resetValues() {
            this.dates = [];
        },
        createRange(dates) {
            let fromTo = {};
            fromTo.from = dates[0];
            fromTo.to = dates[dates.length - 1];
            return fromTo;
        },
        closeDialog() {
            this.resetValues();
            this.dialog = false;
        },
        addDates() {
            let rangesDict = this.createRange(this.dates);
            this.$emit('save', rangesDict);
            this.closeDialog();
        }
    }
};
</script>

<style scoped></style>
