import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './plugins/router';
import { store } from './store/index'
import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import '@/assets/css/styles.css';
import { Vuelidate } from "vuelidate";

Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
