<template>
    <div>
        <v-container fluid fill-height>
            <v-row align="start" justify="center">
                <v-col cols="8">
                    <v-data-table
                        :hide-default-footer="true"
                        :loading="loadingData"
                        loading-text="Cargando datos..."
                    >
                        <template slot="no-data">
                            <div></div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row align="start" justify="center">
                <v-col cols="4">
                    <v-list
                        style="max-height: 50vh"
                        class="overflow-y-auto elevation-3 pa-3 ma-2"
                    >
                        <v-list-item-group
                            v-model="selectedItemIndustrial"
                            color="primary"
                        >
                            <v-subheader>
                                <b>INDUSTRIAL</b>
                            </v-subheader>
                            <draggable
                                v-model="industrial"
                                :options="{
                                    group: 'combine',
                                    pull: 'clone',
                                    put: false
                                }"
                                style="min-height: 10px"
                            >
                                <template v-for="item in industrial">
                                    <v-list-item :key="item.id">
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="item.name"
                                            ></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </draggable>
                        </v-list-item-group>
                    </v-list>
                </v-col>
                <v-col cols="4">
                    <v-list
                        style="max-height: 50vh"
                        class="overflow-y-auto elevation-3 pa-3 ma-2"
                    >
                        <v-list-item-group color="primary">
                            <v-subheader>
                                <b>COMBINADO</b>
                            </v-subheader>
                            <draggable
                                @change="checkMixed"
                                v-model="mixed"
                                group="combine"
                                style="min-height: 10px"
                            >
                                <template v-for="item in mixed">
                                    <v-list-item :key="item.id">
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="item.name"
                                            ></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </draggable>
                        </v-list-item-group>
                    </v-list>
                </v-col>
                <v-col cols="4">
                    <v-list
                        style="max-height: 50vh"
                        class="overflow-y-auto elevation-3 pa-3 ma-2"
                    >
                        <v-list-item-group
                            v-model="selectedItemDomestico"
                            color="primary"
                        >
                            <v-subheader>
                                <b>DOMESTICO</b>
                            </v-subheader>
                            <draggable
                                v-model="domestico"
                                :options="{
                                    group: 'combine',
                                    pull: 'clone',
                                    put: false
                                }"
                                style="min-height: 10px"
                            >
                                <template v-for="item in domestico">
                                    <v-list-item :key="item.id">
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="item.name"
                                            ></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </draggable>
                        </v-list-item-group>
                    </v-list>
                </v-col>
            </v-row>
            <ConfirmDialog ref="confirmCombination" />
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <label
                        style="color: black; padding-left: 10px"
                        class="site-title"
                        >Combinar Predicciones:</label
                    >
                    <v-btn
                        class="ma-2"
                        :loading="calculating"
                        :disabled="
                            loadingData || mixed.length !== 2 || calculating
                        "
                        color="info"
                        @click="getCombinedFile"
                    >
                        Lanzar
                        <div v-if="calculating"></div>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';

Vue.use(VueAxios, axios);
import draggable from 'vuedraggable';
import url_utils from '../helpers/url_utils';

export default {
    name: 'Tab1',
    data() {
        return {
            calculating: false,
            model: {
                selected: null,
                metadata: null
            },
            prediccion: {
                selected: null
            },
            headers: [{ text: 'Name', value: 'name' }],
            predictionDataModified: false,
            listPredictions: [],
            industrial: [],
            selectedItemIndustrial: null,
            domestico: [],
            selectedItemDomestico: null,
            mixed: [],
            loading: false,
            tags: {
                industrial: 'industrial',
                domestico: 'domestico'
            },
            loadingData: false
        };
    },
    computed: {},
    components: {
        draggable,
        ConfirmDialog
    },
    mounted() {
        setTimeout(this.executeRefresh.bind(this), 1000);
    },
    watch: {},
    methods: {
        executeRefresh() {
            this.getPredictionsList();
            setTimeout(this.executeRefresh.bind(this), 60000);
        },
        getPredictionsList() {
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getReturnMetadataSummaryUrl(
                    'predictions',
                    'creationDate'
                ),
                headers: dict_auth
            };

            this.loadingData = true;
            Vue.axios(config).then(response => {
                function PredictionItem(index, dirLabel, consumptionType) {
                    this.index = index;
                    this.name = dirLabel;
                    this.tipo_consumo = consumptionType;
                }
                this.listPredictions = [];
                for (var index in response.data.metadatas) {
                    this.listPredictions.push(
                        new PredictionItem(
                            index,
                            response.data.metadatas[index].dirLabel,
                            response.data.metadatas[index].consumptionType
                        )
                    );
                }
                this.loadingData = false;
                this.splitModelList();
            });
        },
        async getCombinedFile() {
            if (
                await this.$refs.confirmCombination.open(
                    '¿Iniciar combinacion de predicciones? (seguimiento del cálculo en pestaña de Estado Cálculos)',
                    'Confirma la Operación (duración mínima 6 minutos)'
                )
            ) {
                this.calculating = true;

                var labelDomestico = '';
                var labelIndustrial = '';
                for (let i = this.mixed.length - 1; i >= 0; i--) {
                    if (this.mixed[i].tipo_consumo === this.tags.domestico)
                        labelDomestico = this.mixed[i].name;
                    if (this.mixed[i].tipo_consumo === this.tags.industrial)
                        labelIndustrial = this.mixed[i].name;
                }

                setTimeout(() => (this.calculating = true), 10);
                let token = this.$store.getters['getTokenHeader']
                const dict_auth = {
                  Authorization: token
                };
                var config = {
                    method: 'get',
                    url: url_utils.getCombinationUrl(
                        labelIndustrial,
                        labelDomestico
                    ),
                    headers: dict_auth
                };
                Vue.axios(config).then(response => {
                    if (response.status == 200) {
                        setTimeout(() => (this.calculating = false), 10000);
                    }
                });
            } else {
                this.calculating = false;
            }
        },
        checkMixed() {
            let countDom = 0;
            let countInd = 0;
            let new_mixed = [];
            for (let i = this.mixed.length - 1; i >= 0; i--) {
                let is_domestico =
                    this.mixed[i].tipo_consumo === this.tags.domestico;
                let is_industrial =
                    this.mixed[i].tipo_consumo === this.tags.industrial;
                if (countDom >= 1 && is_domestico) {
                    this.domestico.push(this.mixed[i]);
                } else if (countInd >= 1 && is_industrial) {
                    this.industrial.push(this.mixed[i]);
                } else if (is_domestico && countDom === 0) {
                    new_mixed.push(this.mixed[i]);
                    countDom += 1;
                } else if (is_industrial && countInd === 0) {
                    new_mixed.push(this.mixed[i]);
                    countInd += 1;
                }
            }
            this.mixed = new_mixed;
        },
        splitModelList() {
            this.industrial = [];
            this.domestico = [];
            for (let i = 0; i < this.listPredictions.length; i++) {
                let modelo = this.listPredictions[i];
                modelo['id'] = i;
                if (modelo.tipo_consumo === this.tags.industrial) {
                    this.industrial.push(modelo);
                } else {
                    this.domestico.push(modelo);
                }
            }
            this.industrial.sort();
            this.industrial.reverse();
            this.domestico.sort();
            this.domestico.reverse();
        },
        setPredictionDataModified(value) {
            this.predictionDataModified = value;
        }
    }
};
</script>

<style lang="less">
.header-row {
    align-items: center;
}

.hero-image {
    background-position-y: -35px;
}

#app,
#app iframe {
    height: 100vh;
    width: 100vw;
    border: none;
    box-sizing: border-box;
}

body {
    margin: 0;
}
</style>
