<template>
    <v-row align="center" justify="space-around">
        <v-col cols="6">
            <v-data-table
                :headers="headers"
                :items="items"
                sort-by="calories"
                class="elevation-5"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title
                            >Rango de fechas a ignorar</v-toolbar-title
                        >
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <DateRangePickerDialog
                            :title="formTitle"
                            @save="save"
                        ></DateRangePickerDialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h8"
                                    >Está seguro de que desea borrar este
                                    rango?</v-card-title
                                >
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeDelete"
                                        >Cancel</v-btn
                                    >
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="deleteItemConfirm"
                                        >OK</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogInvalid" max-width="1000px">
                            <v-card>
                                <v-card-title class="text-h8"
                                    >Alguno de los valores seleccionados se
                                    encuentra dentro de los rangos ya
                                    introducidos
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeInvalid"
                                        >Ok</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>

<script>
import DateRangePickerDialog from './dialogs/DateRangePickerDialog';
export default {
    name: 'DateRangePicker',
    components: { DateRangePickerDialog },
    data() {
        return {
            items: [],
            minDate: null,
            headers: [
                {
                    text: 'Desde(incluido)',
                    align: 'center',
                    sortable: false,
                    value: 'from'
                },
                { text: 'Hasta(incluido)', value: 'to' },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            dialog: false,
            dialogDelete: false,
            dialogInvalid: false
        };
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1
                ? 'Añadir nuevo rango'
                : 'Editar rango';
        }
    },
    methods: {
        deleteItem(item) {
            this.editedIndex = this.items.indexOf(item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.items.splice(this.editedIndex, 1);
            this.closeDelete();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.editedIndex = -1;
        },
        closeInvalid() {
            this.dialogInvalid = false;
            this.editedIndex = -1;
        },
        isValidRange(newDateItem) {
            let checkFrom = new Date(newDateItem.from).toISOString();
            let checkTo = new Date(newDateItem.to).toISOString();
            for (let i = 0; i < this.items.length; i++) {
                let item = this.items[i];
                let from = new Date(item.from).toISOString();
                let to = new Date(item.to).toISOString();
                if (
                    (checkFrom >= from && checkFrom <= to) ||
                    (checkTo >= from && checkTo <= to)
                ) {
                    return false;
                }
            }
            return true;
        },
        save(item) {
            // let date = new Date(item.to)
            // set format to date, add 1 to month, parse to ISO format and get year and month
            // this.minDate = new Date(date.setMonth(date.getMonth()+1)).toISOString().substr(0,7);
            if (this.isValidRange(item)) {
                this.items.push(item);
                this.$emit('input', this.items);
            } else {
                // Show invalid dialog
                this.dialogInvalid = true;
            }
        }
    }
};
</script>

<style scoped></style>
