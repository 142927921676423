<template>
    <div>
        <v-container fluid>
            <br />
            <v-row align="start" justify="center">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="left">
                    <label
                        style="color: black; padding-left: 10px"
                        class="site-title"
                        ><b>Datos Preprocesados Disponibles</b></label
                    >
                    <v-dialog v-model="infoDatosDisponiblesVisible" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                                >mdi-information</v-icon
                            >
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                Datos preprocesados disponibles
                            </v-card-title>

                            <v-card-text>
                                En la tabla aparecen los datos preprocesados de
                                VIKING junto con la información del tipo de
                                consumo, información básica sobre los registros
                                que contiene y el estado del proceso. Al
                                seleccionar un set podrá visualizar la gestión
                                de errores: ficheros disponibles para descarga
                                con la información resultante de los procesos de
                                validación realizados durante el preprocesado.
                                También el Log del preproceso; información
                                técnica sobre el preprocesado.
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="#004571"
                                    text
                                    @click="infoDatosDisponiblesVisible = false"
                                >
                                    Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-row align="start" justify="center">
                <v-col md="6">
                    <DataList v-model="selecteddataname"> </DataList>
                </v-col>
                <v-col md="6">
                    <v-tabs
                        align-with-title
                        v-if="selecteddataname !== null"
                        :loading="loadingData"
                        color="#004571"
                    >
                        <v-tabs-slider color="#004571"></v-tabs-slider>
                        <v-tab href="#one">Gestión de errores</v-tab>
                        <v-tab href="#two">Log Preproceso</v-tab>

                        <v-tab-item value="one">
                            <!--
                            <br />
                            <br />
                            <v-row align="center" justify="start">
                                <v-col cols="4">
                                    <v-btn
                                        color="secondary"
                                        @click="downloadZIP()"
                                        :disabled="
                                            loading || selecteddata === null
                                        "
                                    >
                                        Descargar todo
                                    </v-btn>
                                </v-col>
                            </v-row>
                            -->
                            <v-row align="start" justify="center">
                                <v-col cols="12" sm="8">
                                    <v-container fill-height>
                                        <v-layout column style="height: 100vh">
                                            <v-flex md6 style="overflow: auto">
                                                <v-data-table
                                                    :headers="csvHeaders"
                                                    :items="csvList"
                                                    :hide-default-footer="true"
                                                    disable-pagination
                                                    fixed-header
                                                    disable-sort
                                                    class="elevation-3"
                                                    :loading="loading"
                                                    loading-text="Loading... Please wait"
                                                >
                                                    <template
                                                        v-slot:body="{ items }"
                                                    >
                                                        <tbody>
                                                            <tr
                                                                v-for="(item,
                                                                index) in items"
                                                                :key="index"
                                                            >
                                                                <td
                                                                    class="text-center"
                                                                >
                                                                    {{ item }}
                                                                </td>
                                                                <td
                                                                    class="text-center"
                                                                >
                                                                    <v-btn
                                                                        color="secondary"
                                                                        @click="
                                                                            downloadCSV(
                                                                                item
                                                                            )
                                                                        "
                                                                        :disabled="
                                                                            loading
                                                                        "
                                                                    >
                                                                        Descargar
                                                                    </v-btn>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-data-table>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item value="two">
                            <br />
                            <v-data-table
                                class="elevation-3"
                                disable-pagination
                                :hide-default-footer="true"
                                :loading="loadingData"
                                loading-text="Cargando datos..."
                            >
                                <v-progress-linear
                                    v-show="loadingData"
                                    slot="progress"
                                    color="#004571"
                                    indeterminate
                                ></v-progress-linear>
                                <template slot="no-data">
                                    <div></div>
                                </template>
                            </v-data-table>
                            <iframe
                                :src="sourceDataInfo"
                                style="border: 0; width: 100%; overflow: auto"
                                allowfullscreen="true"
                                id="iframeResizer0"
                                scrolling="yes"
                                @load="onSourceDataInfoLoad"
                            ></iframe>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
            <v-row align="start" justify="center">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="left">
                    <label
                        style="color: black; padding-left: 10px"
                        class="site-title"
                        ><b>Tablones Disponibles</b></label
                    >
                    <v-dialog
                        v-model="infoTablonesDisponiblesVisible"
                        width="500"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                                >mdi-information</v-icon
                            >
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                Tablones Disponibles
                            </v-card-title>

                            <v-card-text>
                                Tablones con las variables necesarias para el
                                entrenamiento de los modelos y predicción junto
                                con la información del tipo de consumo, los
                                diferentes horizontes temporales y el estado del
                                proceso.
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="#004571"
                                    text
                                    @click="
                                        infoTablonesDisponiblesVisible = false
                                    "
                                >
                                    Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    align="center"
                    justify="start"
                    cols="10"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                >
                    <TrainDataListView></TrainDataListView>
                </v-col>
            </v-row>
            <v-row v-if="isFull" align="center" justify="center">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" align="left">
                    <label
                        style="color: black; padding-left: 10px"
                        class="site-title"
                        ><b
                            >Preprocesado manual de datos (sin exportacion
                            Viking)</b
                        ></label
                    >
                    <v-dialog v-model="infoProcessingVisible" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                                >mdi-information</v-icon
                            >
                        </template>

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                Preprocesado manual de datos
                            </v-card-title>

                            <v-card-text>
                                El preprocesado se realizará sobre las tablas de
                                VIKING de la última exportación disponible. El
                                preprocesado de los datos de consumo industrial
                                y doméstico se realiza en dos pasos: 1. Limpieza
                                y verificación de las tablas individuales
                                descargadas de VIKING y externas (PCTCOG y
                                AGRUPACIONES), formateado de variables y unión
                                de tablas. 2. Generación de los tablones con las
                                variables necesarias para el entrenamiento de
                                los modelos. El preprocesado de variables
                                provenientes de fuentes externas se basa en;
                                formateado de las tablas, variables,
                                normalización de fechas y unión de tablas.
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="#004571"
                                    text
                                    @click="infoProcessingVisible = false"
                                >
                                    Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
                <ConfirmDialog ref="confirmPreprocessing" />
                <v-btn
                    class="ma-2 white--text"
                    :loading="calculatingIndustrial"
                    :disabled="calculatingIndustrial"
                    color="#004571"
                    @click="launchpreprocessingindustrial"
                >
                    Preprocesar Datos Industriales
                    <div v-if="calculatingIndustrial"></div>
                </v-btn>
                <v-btn
                    class="ma-2 white--text"
                    :loading="calculatingDomestico"
                    :disabled="calculatingDomestico"
                    color="#004571"
                    @click="launchpreprocessingdomestico"
                >
                    Preprocesar Datos Domésticos
                    <div v-if="calculatingDomestico"></div>
                </v-btn>
                <v-btn
                    class="ma-2 white--text"
                    :loading="calculatingExternas"
                    :disabled="calculatingExternas"
                    color="#004571"
                    @click="launchpreprocessingexternas"
                >
                    Preprocesar Variables Externas
                    <div v-if="calculatingExternas"></div>
                </v-btn>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import DataList from '@/components/DataList.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import TrainDataListView from '@/components/TrainDataListView.vue';
import utils from '@/helpers/utils';
import url_utils from '../helpers/url_utils';

Vue.use(VueAxios, axios);

export default {
    name: 'Tab6',
    data() {
        return {
            alignments: ['start'],
            calculatingIndustrial: false,
            calculatingDomestico: false,
            calculatingExternas: false,
            csvHeaders: [
                { text: 'Nombre', align: 'center', sortable: false },
                { text: 'Acción', align: 'center', sortable: false }
            ],
            csvList: [],
            loading: false,
            selecteddataname: null,
            loadingData: false,
            infoProcessingVisible: false,
            infoDatosDisponiblesVisible: false,
            infoTablonesDisponiblesVisible: false,
        };
    },
    computed: {
        isFull() {
            return utils.isInterfaceComplete();
        },
        sourceDataInfo() {
            if (this.selecteddataname) {
                return url_utils.getHtmUrl(
                    'preprocessed',
                    this.selecteddataname,
                    'ReportDatasetEntrenamiento.html'
                );
            } else {
                return '';
            }
        }
    },
    components: {
        DataList,
        TrainDataListView,
        ConfirmDialog
    },
    mounted() {},
    watch: {
        selecteddataname: function() {
            this.loadingData = true;
            this.getCsvList();
        }
    },
    methods: {
        getCsvList() {
            if (this.selecteddataname === null) return;

            this.loading = true;

            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };

            var config = {
                method: 'get',
                url: url_utils.getDataprocessedCsvAllUrl(this.selecteddataname),
                headers: dict_auth
            };

            /*
            Fastapi
            var config = {
                method: 'get',
                url:
                    'http://localhost:8000/api/csv/all/'.concat(
                        this.selecteddataname
                    )
            };
            */

            Vue.axios(config)
                .then(response => {
                    if (response.data === null) {
                        this.csvList = [];
                    } else {
                        this.csvList = response.data.models;
                        /*
                        Fastapi
                        this.csvList = response.data;
                        */
                    }
                })
                .catch(error => {
                    console.log('Response error: '.concat(error));
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async launchpreprocessingindustrial() {
            if (
                await this.$refs.confirmPreprocessing.open(
                    '¿Confirma la Operación? (duración mínima 2 horas 2 minutos)',
                    '¿Iniciar el preproceso Industrial de datos (para seguir el proceso consultar la pestaña de Estado Cálculos)?'
                )
            ) {
                this.calculatingIndustrial = true;
                let token = this.$store.getters['getTokenHeader']
                const dict_auth = {
                  'Authorization': token,
                  'Content-Type': "application/json"
                };
                var config = {
                    method: 'get',
                    url: url_utils.getProcessmodelUrl('preprocess_ind'),
                    headers: dict_auth
                };

                Vue.axios(config).then(response => {
                    if (response.status == 200) {
                        setTimeout(
                            () => (this.calculatingIndustrial = false),
                            10000
                        );
                    } else {
                        this.calculatingIndustrial = false;
                    }
                });
            }
        },
        async launchpreprocessingdomestico() {
            if (
                await this.$refs.confirmPreprocessing.open(
                    '¿Confirma la Operación? (duración mínima 1 hora 40 minutos)',
                    '¿Iniciar el preproceso Domestico de datos (para seguir el proceso consultar la pestaña de Estado Cálculos)?'
                )
            ) {
                this.calculatingDomestico = true;
                let token = this.$store.getters['getTokenHeader']
                const dict_auth = {
                  'Authorization': token,
                  'Content-Type': "application/json"
                };
                var config = {
                    method: 'get',
                    url: url_utils.getProcessmodelUrl('preprocess_dom'),
                    headers: dict_auth
                };

                Vue.axios(config).then(response => {
                    if (response.status == 200) {
                        setTimeout(
                            () => (this.calculatingDomestico = false),
                            10000
                        );
                    } else {
                        this.calculatingDomestico = false;
                    }
                });
            }
        },
        async launchpreprocessingexternas() {
            if (
                await this.$refs.confirmPreprocessing.open(
                    'Confirma la Operación (duración mínima 3 minutos)',
                    '¿Iniciar el preproceso de Variables Externas (para seguir el proceso consultar la pestaña de Estado Cálculos)?'
                )
            ) {
                let token = this.$store.getters['getTokenHeader']
                const dict_auth = {
                  'Authorization': token,
                  'Content-Type': "application/json"
                };

                var config = {
                    method: 'get',
                    url: url_utils.getProcessmodelUrl('preprocess_ext'),
                    headers: dict_auth
                };

                Vue.axios(config).then(response => {
                    if (response.status == 200) {
                        this.calculatingExternas = true;
                        setTimeout(
                            () => (this.calculatingExternas = false),
                            10000
                        );
                    }
                });
            }
        },
        downloadCSV(name) {
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getDataprocessedCsvPresignedFileUrl(
                    this.selecteddataname,
                    name
                ),
                headers: dict_auth
            };

            /*
            Fastapi
            Vue.axios
                .get('http://localhost:8000/api/csv/'.concat(name), {
                    responseType: 'blob'
                })
            */
            Vue.axios(config)
                .then(response => {
                    //utils.downloadCSV(response.data, name);
                    const url = response.data;
                    utils.downloadFromUrl(url);
                })
                .catch(error => {
                    console.log('Response error: '.concat(error));
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        downloadZIP() {
            Vue.axios
                .get(
                    'http://localhost:8000/api/csv/zip/'.concat(
                        this.selecteddataname
                    ),
                    { responseType: 'blob' }
                )
                .then(response => {
                    utils.downloadZIP(response.data, this.selecteddataname);
                })
                .catch(error => {
                    console.log('Response error: '.concat(error));
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSourceDataInfoLoad() {
            this.loadingData = false;
        },
        infoProcessing() {
            this.infoProcessingVisible = true;
        },
        async delRecord() {}
    }
};
</script>

<style lang="less">
.header-row {
    align-items: center;
}
.hero-image {
    background-position-y: -35px;
}

#app,
#app iframe {
    height: 100vh;
    width: 100vw;
    border: none;
    box-sizing: border-box;
}
body {
    margin: 0;
}
</style>
