<template>
    <v-container fluid>
        <v-row align="center" justify="center">
            <v-col cols="10">
                <v-subheader>Modelos Entrenados Disponibles</v-subheader>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col cols="10">
                <v-data-table
                    :value="selectedModel"
                    :headers="headers"
                    :items="receivedList"
                    :single-select="true"
                    item-key="index"
                    show-select
                    class="elevation-1"
                    @input="generateInputModel"
                    :loading="loadingData"
                    loading-text="Cargando datos... "
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                >
                    <v-progress-linear
                        v-show="loadingData"
                        slot="progress"
                        color="#004571"
                        indeterminate
                    ></v-progress-linear>
                </v-data-table>
            </v-col>
        </v-row>
        <ConfirmationModelListDialog
            v-model="dialog"
            @setNewValue="setSelectedModel($event)"
            @setOldValue="setSelectedModel($event)"
        ></ConfirmationModelListDialog>
    </v-container>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ConfirmationModelListDialog from '@/components/dialogs/ConfirmationModelListDialog';
import url_utils from '../helpers/url_utils';

Vue.use(VueAxios, axios);

export default {
    name: 'ModelList',
    props: ['value', 'enabledDialog'],
    components: { ConfirmationModelListDialog },
    data() {
        return {
            receivedList: [],
            loadingData: false,
            dialog: false,
            temporalModel: null,
            model: this.value,
            headers: [
                {
                    text: 'Label',
                    align: 'start',
                    sortable: false,
                    value: 'label'
                },
                {
                    text: 'Identificador Datos',
                    align: 'start',
                    sortable: false,
                    value: 'trainData'
                },
                {
                    text: 'Tipo Consumo',
                    align: 'start',
                    sortable: false,
                    value: 'consumptionType'
                },
                /*
                {
                    text: 'Estructura Entrenamiento',
                    align: 'start',
                    sortable: false,
                    value: 'dataStruct'
                },
                */
                {
                    text: 'Variables',
                    align: 'start',
                    sortable: false,
                    value: 'parametrical_features'
                },
                {
                    text: 'Fecha Creación',
                    align: 'start',
                    sortable: false,
                    value: 'creationdate'
                },
                {
                    text: 'Periodos Excluidos',
                    align: 'start',
                    sortable: false,
                    value: 'excludedPeriods'
                },
                {
                    text: 'Periodo Entrenamiento',
                    align: 'start',
                    sortable: false,
                    value: 'train_period'
                },
                {
                    text: 'Periodo Validacion',
                    align: 'start',
                    sortable: false,
                    value: 'validation_period'
                },
                {
                    text: 'Score',
                    align: 'start',
                    sortable: false,
                    value: 'score'
                },
                {
                    text: 'Automático',
                    align: 'start',
                    sortable: false,
                    value: 'auto'
                },
                {
                    text: 'Resultado',
                    align: 'start',
                    sortable: false,
                    value: 'resultado'
                }
            ],
            sortBy: 'label',
            sortDesc: true
        };
    },
    mounted() {
        setTimeout(this.executeRefresh.bind(this), 1000);
    },
    computed: {
        selectedModel() {
            return [this.model.selected];
        }
    },
    methods: {
        executeRefresh() {
            this.getModelsList();
            setTimeout(this.executeRefresh.bind(this), 60000);
        },
        generateInputModel(val) {
            if (val.length > 0) {
                const val_input = val[0];
                // this if is because sometimes val arrives with [null] format
                if (val_input === null) {
                    this.resetModelValues();
                } else if (
                    this.model.selected === null ||
                    this.model.selected.label.toUpperCase() !==
                        val_input.label.toUpperCase()
                ) {
                    if (this.enabledDialog === true) {
                        this.showDialog(val_input);
                    } else {
                        this.selectmodel(val_input);
                    }
                }
            } else {
                this.resetModelValues();
            }
        },
        showDialog(val) {
            this.temporalModel = val;
            if (this.model.selected === null) {
                this.selectmodel(val);
            } else {
                this.temporalModel = this.model.selected;
                this.setModelSelected(val);
                this.dialog = true;
            }
        },
        setSelectedModel(setNewValue) {
            if (setNewValue === false) {
                this.setModelSelected(this.temporalModel);
            } else {
                this.selectmodel(this.model.selected);
            }
        },
        getModelsList() {
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getReturnMetadataSummaryUrl(
                    'model',
                    'creationDate'
                ),
                headers: dict_auth
            };

            this.loadingData = true;
            Vue.axios(config).then(response => {
                function ModelItem(
                    index,
                    label,
                    trainData,
                    creationDate,
                    consumptionType,
                    dataStruct,
                    parametrical_features,
                    excludedPeriods,
                    train_period,
                    validation_period,
                    score,
                    auto,
                    resultado
                ) {
                    this.index = index;
                    this.label = label;
                    this.trainData = trainData;
                    this.creationdate = creationDate;
                    this.consumptionType = consumptionType;
                    this.dataStruct = dataStruct;
                    this.parametrical_features = parametrical_features;
                    this.excludedPeriods = excludedPeriods;
                    this.train_period = train_period;
                    this.validation_period = validation_period;
                    this.score = score;
                    this.auto = auto;
                    this.resultado = resultado;
                }

                var itemList = [];
                for (var index in response.data.metadatas) {
                    var estadoProceso;
                    if (response.data.metadatas[index].inProcess == true)
                        estadoProceso = 'Procesando';
                    else estadoProceso = 'Ok';

                    var automatico;
                    if (response.data.metadatas[index].auto == true)
                        automatico = 'Sí';
                    else automatico = 'No';

                    var fechasExcluidas = '';
                    for (var indexPeriod in response.data.metadatas[index]
                        .excludedPeriods) {
                        for (var eachPeriod in response.data.metadatas[index]
                            .excludedPeriods[indexPeriod]) {
                            var separador = ' ';
                            if (eachPeriod == 0) separador = ',';
                            fechasExcluidas =
                                fechasExcluidas +
                                response.data.metadatas[index].excludedPeriods[
                                    indexPeriod
                                ][eachPeriod] +
                                separador;
                        }
                    }

                    itemList.push(
                        new ModelItem(
                            index,
                            response.data.metadatas[index].dirLabel,
                            response.data.metadatas[index].trainData,
                            response.data.metadatas[index].creationDate,
                            response.data.metadatas[index].consumptionType,
                            response.data.metadatas[index].dataStruct,
                            response.data.metadatas[
                                index
                            ].parametrical_features,
                            fechasExcluidas,
                            response.data.metadatas[index].train_period,
                            response.data.metadatas[index].validation_period,
                            response.data.metadatas[index].score,
                            automatico,
                            estadoProceso
                        )
                    );
                }
                this.receivedList = itemList;
                this.loadingData = false;
            });
        },
        selectmodel(val) {
            this.setModelSelected(val);
            let token = this.$store.getters['getTokenHeader']
            const dict_auth = {
              'Authorization': token,
              'Content-Type': "application/json"
            };
            var config = {
                method: 'get',
                url: url_utils.getFileJsonUrl(
                    'models',
                    this.model.selected.label,
                    'metaData.json'
                ),
                headers: dict_auth
            };

            Vue.axios(config).then(response => {
                this.setModelMetadata(response.data);
                this.emitModel();
            });
        },
        resetModelValues() {
            this.setModelMetadata(null);
            this.setModelSelected(null);
            this.emitModel();
        },
        setModelMetadata(newMetadata) {
            this.model.metadata = newMetadata;
        },
        setModelSelected(newSelected) {
            this.model.selected = newSelected;
        },
        emitModel() {
            this.$emit('input', this.model);
        }
    }
};
</script>
