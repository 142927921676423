<template>
    <v-app>
        <app-header v-if="isUsserLogged"/>
            <v-main>
                <router-view />
            </v-main>
        <app-footer v-if="isUsserLogged"/>
    </v-app>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
    components: {
        AppHeader,
        AppFooter
    },
    created() {
        this.$store.dispatch('tryAutoSignIn');
    },
    computed: {
      isUsserLogged() {
        return this.$store.getters['isUserAuthenticated'];
      }
    }
};
</script>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
