<template>
  <v-container fluid fill-height>
    <v-row class="fill-height" align="center" justify="center">
      <v-col cols="12" xs="12" sm="10" md="8" lg="4" xl="4">
        <v-card class="elevation-12">
          <v-card-title primary-title class="justify-center">
            <v-toolbar dark color="#004571">
              <v-row align="center" justify="space-between">
                <v-col cols="12">
                  <img alt="Vue logo" src="../../assets/logo.svg" height="50">
                </v-col>
              </v-row>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-alert
                v-if="errmsg !== ''"
                color="red"
                dense
                prominent
                dismissible
                type="error"
            >
              {{ errmsg }}
            </v-alert>
            <form ref="form">
              <div class="form-group">
                <v-text-field
                    v-model="user.username"
                    name="username"
                    label="Username"
                    type="text"
                    placeholder="username"
                    :error-messages="checkUsername"
                ></v-text-field>
              </div>
              <div class="form-group">
                <v-text-field
                    v-model="user.oldPassword"
                    name="antiguoPassword"
                    label="Antiguo password"
                    type="password"
                    placeholder="antiguo password"
                    :error-messages="checkOldPassword"
                ></v-text-field>
              </div>
              <div class="form-group">
                <v-text-field
                    v-model="user.newPassword"
                    name="newPassword"
                    label="Nueva password"
                    type="password"
                    placeholder="new password"
                    :error-messages="checkNewPassword"
                ></v-text-field>
              </div>
              <div class="form-group">
                <v-text-field
                    v-model="user.confirmPassword"
                    name="confirmPassword"
                    label="Confirme Password"
                    type="password"
                    placeholder="Confirmar password"
                    :error-messages="checkConfirmPassword"
                ></v-text-field>
              </div>
              <v-btn class="ma-2 white--text"
                     color="#004571"
                     :disabled="loading"
                     @click="handleSubmit()">
                Confirmar cambio
              </v-btn>
            </form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "newPassword",
  data () {
    return {
      user: {
        username: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      },
      submitted: false,
      loading: false,
    }
  },
  validations: {
    user: {
      username: { required },
      oldPassword: {
        required,
        minLength: minLength(8) },
      newPassword: {
        required,
        minLength: minLength(8),
        containsPasswordRequirement: function(value) {
          return this.verifyPasswordRequirements(value)
        },
      },
      confirmPassword: {
        required,
        containsPasswordRequirement: function(value) {
          return this.verifyPasswordRequirements(value)
        },
        sameAsPassword: sameAs(function() {
          return this.user.newPassword;
        }),
        minLength: minLength(8)
      }
    }
  },
  computed: {
    errmsg: function () {
      return this.$store.getters['getErrMessage']
    },
    checkUsername: function() {
      const errors = [];
      if (!this.$v.user.username.$dirty) return null;
      if (!this.$v.user.username.required) errors.push('Usuario es obligatorio');
      return errors
    },
    checkOldPassword: function() {
      const errors = [];
      if (!this.$v.user.oldPassword.$dirty) return null;
      if (!this.$v.user.oldPassword.required) errors.push('Password es obligatorio');
      if (!this.$v.user.oldPassword.minLength) errors.push('La longitud mínima es 8');
      return errors
    },
    checkNewPassword: function() {
      const errors = [];
      if (!this.$v.user.newPassword.$dirty) return null;
      if (!this.$v.user.newPassword.required) errors.push('Password es obligatorio');
      if (!this.$v.user.newPassword.minLength) errors.push('La longitud mínima es 8')
      if (!this.$v.user.newPassword.containsPasswordRequirement) errors.push('Password requiere mayúscula, minúscula, número y caracter especial');
      return errors
    },
    checkConfirmPassword: function() {
      const errors = [];
      if (!this.$v.user.confirmPassword.$dirty) return null;
      if (!this.$v.user.confirmPassword.required) errors.push('Password es obligatorio');
      if (!this.$v.user.confirmPassword.minLength) errors.push('La longitud mínima es 8');
      if (!this.$v.user.confirmPassword.containsPasswordRequirement) errors.push('Password requiere mayúscula, minúscula, número y caracter especial');
      if (!this.$v.user.confirmPassword.sameAsPassword) errors.push('La contraseña debe coincidir con la contraseña nueva');

      return errors
    },
  },
  methods: {
    handleSubmit () {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true

      let authData = {
        Username: this.user.username,
        Password: this.user.oldPassword,
        NewPassword: this.user.newPassword
      }
      this.$store.dispatch('newPassword', authData)
      this.loading = false
    },
    verifyPasswordRequirements(value) {
      const containsUppercase = /[A-Z]/.test(value)
      const containsLowercase = /[a-z]/.test(value)
      const containsNumber = /[0-9]/.test(value)
      const containsSpecial = /[#?!@$%^&*-]/.test(value)
      return containsUppercase && containsLowercase && containsNumber && containsSpecial
    }
  },
}
</script>

<style scoped>

</style>
