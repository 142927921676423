var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"child-flex":""}},[_c('v-row',{attrs:{"align":"start","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-layout',{staticStyle:{"height":"130vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"scroll"},attrs:{"md7":""}},[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.tableList,"hide-default-footer":false,"fixed-header":"","disable-sort":"","items-per-page":14,"loading":_vm.loading,"loading-text":"Cargando datos... ","footer-props":{
                              showFirstLastPage: true
                            }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                            var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:index,class:{
                                            'primary lighten-5':
                                                _vm.selectedImageIndex === index
                                        },on:{"click":function($event){_vm.selectedImageIndex = index}}},[_c('td',{staticClass:"overflow-row-attempt overflow-row-visible text-center"},[_vm._v(" "+_vm._s(item.url)+" ")])])}),0)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-window',{staticClass:"elevation-3",staticStyle:{"margin-bottom":"2%"}},[(
                            _vm.pngList.length !== 0 &&
                                _vm.selectedImageIndex !== null
                        )?_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('ImagesThumbnail',{attrs:{"imageName":_vm.getSelectedItem.name,"imageURL":_vm.getSelectedItem.url,"showButton":false,"modelName":_vm.modelName}})],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('br'),_c('br'),_c('br')])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }