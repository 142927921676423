var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"list px-3 mx-auto",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"md":"4"}},[_c('v-select',{attrs:{"items":_vm.options,"allow-empty":false,"label":"Parámetro","dense":"","outlined":""},on:{"change":_vm.setDefaultRange},model:{value:(_vm.selectedvariable),callback:function ($$v) {_vm.selectedvariable=$$v},expression:"selectedvariable"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"md":"4"}},[_c('v-select',{attrs:{"items":_vm.rangeOptions,"disabled":_vm.selectedvariable === null,"label":"Valor de Referencia","dense":"","outlined":""},model:{value:(_vm.selectedrange),callback:function ($$v) {_vm.selectedrange=$$v},expression:"selectedrange"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.selectedvariable === null || _vm.selectedrange === null,"color":"info"},on:{"click":function($event){return _vm.setPredictionParameters()}}},[_vm._v(" Seleccionar Referencia ")])],1)],1),_c('v-row',{staticClass:"list px-3 mx-auto",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Parámetro "+_vm._s(_vm.selectedvariable === null ? '' : _vm.selectedvariable))]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getParametros,"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [_c('tbody',_vm._l((items),function(item,idx){return _c('tr',{key:idx},_vm._l((headers),function(header,key){return _c('td',{key:key},[_c('v-edit-dialog',{attrs:{"return-value":item[header.value],"large":""},on:{"update:returnValue":function($event){return _vm.$set(item, header.value, $event)},"update:return-value":function($event){return _vm.$set(item, header.value, $event)},"save":_vm.save,"cancel":_vm.cancel},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" Update value ")]),_c('v-text-field',{attrs:{"value":item[header.value],"label":"Edit","type":"number","single-line":""},on:{"input":function($event){return _vm.setPredictionParameter(
                                                        $event,
                                                        header.value
                                                    )}}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item[header.value].toFixed(3))+" ")])],1)}),0)}),0)]}}])})],1)],1)],1),_c('v-row',{staticClass:"list px-3 mx-auto",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Parámetros seleccionados")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.selectedPredictionHeaders,"items":_vm.getPredictionParameters,"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                                                    var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.key))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.range)+" ")])])}),0)]}}])}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)],1)],1),(_vm.selectedIsIndustrial)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"site-title",staticStyle:{"color":"black","padding-left":"10px"}},[_vm._v("Predicción Industrial:")]),_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.calculating.ind,"disabled":_vm.calculating.ind || _vm.selectedrange === null,"color":"info"},on:{"click":function($event){return _vm.launchEventPrediction('IND')}}},[_vm._v(" Lanzar "),(_vm.calculating.ind)?_c('div'):_vm._e()])],1)],1):_vm._e(),(_vm.selectedIsDomestico)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"site-title",staticStyle:{"color":"black","padding-left":"10px"}},[_vm._v("Predicción Domestico:")]),_c('v-btn',{staticClass:"ma-2",attrs:{"loading":_vm.calculating.dom,"disabled":_vm.calculating.dom || _vm.selectedrange === null,"color":"info"},on:{"click":function($event){return _vm.launchEventPrediction('DOM')}}},[_vm._v(" Lanzar "),(_vm.calculating.dom)?_c('div'):_vm._e()])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }